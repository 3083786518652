import {
  IDwollaCustomer,
  IDwollaBeneficial,
  IDwollaCustomerDisableList,
  IDwollaCustomerController,
  IDwollaCustomerControllerAddress,
  IDwollaCustomerControllerPassport
} from '../../../models/IDwollaCustomer';

export interface DwollaState {
  customer: IDwollaCustomer;
  beneficials: any;
  disableList: IDwollaCustomerDisableList;
  controllerDisable: boolean;
  isLoading: boolean;
  error: string;
}

export enum DwollaEnum {
  SET_DWOLLA_CUSTOMER = 'SET_DWOLLA_CUSTOMER',
  SET_DWOLLA_CUSTOMER_DISABLE_LIST = 'SET_DWOLLA_CUSTOMER_DISABLE_LIST',
  SET_DWOLLA_CONTROLLER_DISABLE = 'SET_DWOLLA_CONTROLLER_DISABLE',
  UPDATE_DWOLLA_CUSTOMER = 'UPDATE_DWOLLA_CUSTOMER',
  UPDATE_DWOLLA_CUSTOMER_CONTROLLER = 'UPDATE_DWOLLA_CUSTOMER_CONTROLLER',
  UPDATE_DWOLLA_CUSTOMER_CONTROLLER_ADDRESS = 'UPDATE_DWOLLA_CUSTOMER_CONTROLLER_ADDRESS',
  UPDATE_DWOLLA_CUSTOMER_CONTROLLER_PASSPORT = 'UPDATE_DWOLLA_CUSTOMER_CONTROLLER_PASSPORT',
  ADDING_BENEFICIAL_OWNERS = 'ADDING_BENEFICIAL_OWNERS',
  REMOVE_BENEFICIAL_OWNERS = 'REMOVE_BENEFICIAL_OWNERS',
  EDIT_BENEFICIAL_OWNERS = 'EDIT_BENEFICIAL_OWNERS',
  EDIT_BENEFICIAL_OWNERS_ADDRESS = 'EDIT_BENEFICIAL_OWNERS_ADDRESS',
  EDIT_BENEFICIAL_OWNERS_PASSPORT = 'EDIT_BENEFICIAL_OWNERS_PASSPORT',
  SET_IS_LOADING = 'SET_IS_LOADING_DWOLLA',
  SET_ERROR = 'SET_ERROR_DWOLLA'
}

export interface SetDwollaCustomerAction {
  type: DwollaEnum.SET_DWOLLA_CUSTOMER;
  payload: IDwollaCustomer;
}
export interface SetDwollaCustomerDisableListAction {
  type: DwollaEnum.SET_DWOLLA_CUSTOMER_DISABLE_LIST;
  payload: IDwollaCustomerDisableList;
}
export interface SetDwollaControllerDisableAction {
  type: DwollaEnum.SET_DWOLLA_CONTROLLER_DISABLE;
  payload: boolean;
}
export interface UpdateDwollaCustomerAction {
  type: DwollaEnum.UPDATE_DWOLLA_CUSTOMER;
  payload: IDwollaCustomer;
}
export interface UpdateDwollaCustomerControllerAction {
  type: DwollaEnum.UPDATE_DWOLLA_CUSTOMER_CONTROLLER;
  payload: IDwollaCustomerController;
}
export interface UpdateDwollaCustomerControllerAddressAction {
  type: DwollaEnum.UPDATE_DWOLLA_CUSTOMER_CONTROLLER_ADDRESS;
  payload: IDwollaCustomerControllerAddress;
}
export interface UpdateDwollaCustomerControllerPassportAction {
  type: DwollaEnum.UPDATE_DWOLLA_CUSTOMER_CONTROLLER_PASSPORT;
  payload: IDwollaCustomerControllerPassport;
}
export interface AddingBeneficialOwnersAction {
  type: DwollaEnum.ADDING_BENEFICIAL_OWNERS;
  payload: IDwollaBeneficial;
}
export interface RemoveBeneficialOwnersAction {
  type: DwollaEnum.REMOVE_BENEFICIAL_OWNERS;
  payload: number;
}
export interface EditBeneficialOwnersAction {
  type: DwollaEnum.EDIT_BENEFICIAL_OWNERS;
  payload: any;
}
export interface EditBeneficialOwnersAddressAction {
  type: DwollaEnum.EDIT_BENEFICIAL_OWNERS_ADDRESS;
  payload: any;
}
export interface EditBeneficialOwnersPassportAction {
  type: DwollaEnum.EDIT_BENEFICIAL_OWNERS_PASSPORT;
  payload: any;
}
export interface SetIsLoadingAction {
  type: DwollaEnum.SET_IS_LOADING;
  payload: boolean;
}
export interface SetErrorAction {
  type: DwollaEnum.SET_ERROR;
  payload: string;
}

export type DwollaAction =
  | SetDwollaCustomerAction
  | SetDwollaCustomerDisableListAction
  | SetDwollaControllerDisableAction
  | UpdateDwollaCustomerAction
  | UpdateDwollaCustomerControllerAction
  | UpdateDwollaCustomerControllerAddressAction
  | UpdateDwollaCustomerControllerPassportAction
  | AddingBeneficialOwnersAction
  | RemoveBeneficialOwnersAction
  | EditBeneficialOwnersAction
  | EditBeneficialOwnersAddressAction
  | EditBeneficialOwnersPassportAction
  | SetIsLoadingAction
  | SetErrorAction;
