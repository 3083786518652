import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import logoImg2 from '../../assets/images/landing/logo3.png';
import logoImg2Small from '../../assets/images/landing/logo3-small.png';
import logoIcon from '../../assets/images/landing/icon-logo.png';

interface ComponentProps {}

export const HeaderLogo: FC<ComponentProps> = () => {
    const [logoImg, setLogoImg] = useState<string>('');

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 650) {
                setLogoImg(logoIcon);
            } else if (window.innerWidth <= 1080) {
                setLogoImg(logoImg2Small);
            } else {
                setLogoImg(logoImg2);
            }
        };

        handleResize(); // Set initial logo image

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return <Logo logoImg={logoImg} />;
};

interface LogoProps {
    logoImg: string;
}

const Logo = styled.div<LogoProps>`
  background: url(${(props) => props.logoImg});
  background-repeat: no-repeat;
  background-position: left center; // добавляем это свойство
  background-size: contain;
  width: 100%;
  height: 100%;
  max-height: 90px;
  @media (max-width: 1080px) {
    max-width: 330px;
    max-height: 80px;
  }
`;