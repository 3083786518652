import { Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { FC, memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import Service from '../../../API/service';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { IBankAccount } from '../../../models/IBankAccount';
import { ITransfer, ITransferHistory } from '../../../models/ITransfer';
import { Loader } from '../../UI/Loader';
import { TransferItem } from './TransferItem';
import { TransferHistoryItem } from './TransferHistoryItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

interface Props {
  showActive: boolean;
  isLoading: boolean;
  setIsLoading: any;
}

export const TransferList: FC<Props> = memo(({ showActive, isLoading, setIsLoading }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { accounts, defaultAccount } = useTypedSelector(({ bankAccounts }) => bankAccounts);
  const accountsMap: Map<string, IBankAccount> = accounts.reduce(
    (map, account) => map.set(account.dwolla_url, account),
    new Map()
  );

  const [transfers, setTransfers] = useState([]);
  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(true);

  function loadTransfers(showActive: boolean, page: number = 1) {
    setIsLoading(true);
    Service.transfers(showActive, page)
      .then(({ data }) => {
        if (!data?.length) {
          setHasNext(false);
        }

        if (showActive) {
          data = [
            ...data.filter(
              (transfer: ITransfer) =>
                new Date().getTime() - new Date(transfer.date_range).getTime() > 0
            )
          ];
        }

        const updated = [...transfers, ...data];
        setTransfers(updated as never[]);
      })
      .catch(e =>
        enqueueSnackbar('Something went wrong. Please try again later.', {
          variant: 'error'
        })
      )
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    loadTransfers(showActive, page);
  }, []);

  useEffect(() => {
    if (page > 1) {
      loadTransfers(showActive, page);
    }
  }, [page]);

  function loadNextPage() {
    setPage(page + 1);
  }

  return (
    <>
      {(transfers.length && showActive) ||
      (!showActive &&
        transfers.filter((transfer: ITransferHistory) => transfer.amount !== null).length) ? (
        <TransfersContainer>
          {showActive ? (
            transfers.map((transfer: ITransfer) => {
              return (
                <TransferItem
                  key={transfer.id}
                  transfer={transfer}
                  toAccount={
                    accountsMap.has(transfer.to_funding_sources)
                      ? accountsMap.get(transfer.to_funding_sources) ?? null
                      : null
                  }
                  amountDue={defaultAccount.balance ? defaultAccount.balance : 0}
                />
              );
            })
          ) : (
            <TableContainer
              sx={{
                boxShadow: 'none',
                borderRadius: 0,
                background: '#F1F5F9',
                marginBottom: '20px'
              }}
              component={Paper}>
              <Table size='small' aria-label='a dense table'>
                <TableHead>
                  <TableRow
                    sx={{
                      '& .MuiTableCell-root': {
                        fontWeight: 500,
                        height: '42px',
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: '#0F172A'
                      }
                    }}>
                    <TableCell>Date</TableCell>
                    <TableCell>Withdrawal Account</TableCell>
                    <TableCell>Deposit Account</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transfers
                    .filter((transfer: ITransferHistory) => transfer.amount !== null)
                    .map((transfer: ITransferHistory) => {
                      return <TransferHistoryItem key={transfer.id} transfer={transfer} />;
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {isLoading ? (
            <Loader margin='30px 0 0' color='#94a3b8' width='50px' height='50px' />
          ) : (
            hasNext && (
              <LoadMoreContainer>
                <Button className='active' onClick={() => loadNextPage()}>
                  Load more...
                </Button>
              </LoadMoreContainer>
            )
          )}
        </TransfersContainer>
      ) : isLoading ? (
        <Loader margin='30px 0 0' color='#94a3b8' width='50px' height='50px' />
      ) : (
        <TransfersNotFound>
          <Typography variant='h5' textAlign='center'>
            Transfers Not Found
          </Typography>
        </TransfersNotFound>
      )}
    </>
  );
});

const TransfersContainer = styled.div`
  height: calc(100% - 30px);
  overflow-y: auto;
  margin-top: 15px;
`;

const TransfersNotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
`;
