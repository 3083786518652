import {
  TransactionAction,
  TransactionActionsEnum,
  TransactionState,
} from './types';

const initialState: TransactionState = {
  amount: 0,
  isLoading: false,
};

export default function transactionReducer(
  state = initialState,
  action: TransactionAction
) {
  switch (action.type) {
    case TransactionActionsEnum.SET_TRANSACTION:
      return { ...state, amount: action.payload };
    case TransactionActionsEnum.SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
}
