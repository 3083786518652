import {
  IDwollaBeneficial,
  IDwollaCustomer,
  IDwollaCustomerDisableList
} from '../../../models/IDwollaCustomer';
import { DwollaAction, DwollaEnum, DwollaState } from './types';

const initialState: DwollaState = {
  customer: {} as IDwollaCustomer,
  beneficials: [] as any,
  disableList: {} as IDwollaCustomerDisableList,
  controllerDisable: false,
  isLoading: false,
  error: ''
};

export default function dwollaReducer(state = initialState, action: DwollaAction): DwollaState {
  switch (action.type) {
    case DwollaEnum.SET_DWOLLA_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
        isLoading: false
      };
    case DwollaEnum.UPDATE_DWOLLA_CUSTOMER:
      return {
        ...state,
        customer: { ...state.customer, ...action.payload },
        isLoading: false
      };
    case DwollaEnum.UPDATE_DWOLLA_CUSTOMER_CONTROLLER:
      return {
        ...state,
        customer: {
          ...state.customer,
          controller:
            action.payload === null ? null : { ...state.customer.controller, ...action.payload }
        },
        isLoading: false
      };
    case DwollaEnum.UPDATE_DWOLLA_CUSTOMER_CONTROLLER_ADDRESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          controller: {
            ...state.customer.controller,
            address: {
              ...state.customer.controller?.address,
              ...action.payload
            }
          }
        },
        isLoading: false
      };
    case DwollaEnum.UPDATE_DWOLLA_CUSTOMER_CONTROLLER_PASSPORT:
      return {
        ...state,
        customer: {
          ...state.customer,
          controller: {
            ...state.customer.controller,
            passport:
              action.payload === null
                ? null
                : { ...state.customer.controller?.passport, ...action.payload }
          }
        },
        isLoading: false
      };
    case DwollaEnum.SET_DWOLLA_CUSTOMER_DISABLE_LIST:
      return {
        ...state,
        disableList: action.payload
      };
    case DwollaEnum.SET_DWOLLA_CONTROLLER_DISABLE:
      return {
        ...state,
        controllerDisable: action.payload
      };
    case DwollaEnum.ADDING_BENEFICIAL_OWNERS:
      return {
        ...state,
        beneficials: [...state.beneficials, action.payload]
      };
    case DwollaEnum.REMOVE_BENEFICIAL_OWNERS:
      return {
        ...state,
        beneficials: state.beneficials.filter((item: any, i: number) => i !== action.payload)
      };
    case DwollaEnum.EDIT_BENEFICIAL_OWNERS:
      return {
        ...state,
        beneficials: state.beneficials.map((item: any, i: number) =>
          i === action.payload.item ? { ...item, ...action.payload.data } : item
        )
      };

    case DwollaEnum.EDIT_BENEFICIAL_OWNERS_ADDRESS:
      return {
        ...state,
        beneficials: state.beneficials.map((item: any, i: number) =>
          i === action.payload.item
            ? {
                ...item,
                address:
                  action.payload.data === null
                    ? null
                    : {
                        ...item.address,
                        ...action.payload.data
                      }
              }
            : item
        )
      };
    case DwollaEnum.EDIT_BENEFICIAL_OWNERS_PASSPORT:
      return {
        ...state,
        beneficials: state.beneficials.map((item: any, i: number) =>
          i === action.payload.item
            ? {
                ...item,
                passport:
                  action.payload.data === null
                    ? null
                    : {
                        ...item.passport,
                        ...action.payload.data
                      }
              }
            : item
        )
      };
    case DwollaEnum.SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case DwollaEnum.SET_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
  }
}
