import { FC } from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import whiteBackImg from '../../../assets/images/landing/white-back.png';
import cardIconImg from '../../../assets/images/landing/card-icon.png';
import bellIconImg from '../../../assets/images/landing/bell-icon.png';
import transferIconImg from '../../../assets/images/landing/transfer-icon.png';
import categoryImg from '../../../assets/images/landing/category.png';
import calendarImg from '../../../assets/images/landing/calendar.png';
import transferImg from '../../../assets/images/landing/transfer.png';
import leftImg from '../../../assets/images/landing/left.png';
import rightImg from '../../../assets/images/landing/right.png';

interface Props {}

export const Info: FC<Props> = ({}) => {
  return (
    <StyledModule>
      <StyledInfoHeader>
        Features that will
        <br /> make your life easier than ever
      </StyledInfoHeader>
      <StyledInfoP>
        In venenatis eu ligula vitae imperdiet. Nam in ornare mauris. Duis auctor a massa in mollis.
        Donec fermentum, quam a auctor cursus, nibh massa rhoncus dui, sagittis imperdiet magna
        justo quis augue.
      </StyledInfoP>
      <StyledInfoItem>
        <img src={categoryImg} alt='category' />
        <StyledInfoItemText style={{ margin: '60px 50px 0 0' }}>
          <StyledInfoItemTextH>
            <img src={cardIconImg} alt='card-icon' />
            Link accounts and set your Allocation % for each category
          </StyledInfoItemTextH>
          <StyledInfoItemTextP>
            In venenatis eu ligula vitae imperdiet. Nam in ornare mauris. Duis auctor a massa in
            mollis. Donec fermentum, quam a auctor cursus, nibh massa rhoncus dui, sagittis
            imperdiet magna justo quis augue. Curabitur malesuada felis ac neque suscipit
            pellentesque id ac lorem. Ut varius mauris arcu, nec fermentum quam blandit et. Aenean
            tortor nulla, ultricies sed blandit non, tincidunt nec sapien.
          </StyledInfoItemTextP>
        </StyledInfoItemText>
      </StyledInfoItem>
      <StyledRight src={rightImg} alt='right' />
      <StyledInfoItem style={{ marginTop: '-124px' }}>
        <StyledInfoItemText style={{ margin: '98px 0 0 160px' }}>
          <StyledInfoItemTextH>
            <img src={bellIconImg} alt='bell-icon' />
            Set reminders and calculate transfers based on income
          </StyledInfoItemTextH>
          <StyledInfoItemTextP>
            In venenatis eu ligula vitae imperdiet. Nam in ornare mauris. Duis auctor a massa in
            mollis. Donec fermentum, quam a auctor cursus, nibh massa rhoncus dui, sagittis
            imperdiet magna justo quis augue. Curabitur malesuada felis ac neque suscipit
            pellentesque id ac lorem. Ut varius mauris arcu, nec fermentum quam blandit et. Aenean
            tortor nulla, ultricies sed blandit non, tincidunt nec sapien.
          </StyledInfoItemTextP>
        </StyledInfoItemText>
        <img src={calendarImg} alt='calendar' />
      </StyledInfoItem>
      <StyledLeft src={leftImg} alt='left' />
      <StyledInfoItem style={{ marginTop: '-144px' }}>
        <img src={transferImg} alt='transfer' />
        <StyledInfoItemText>
          <StyledInfoItemTextH>
            <img src={transferIconImg} alt='transfer-icon' />
            Instant Balance Transfer
          </StyledInfoItemTextH>
          <StyledInfoItemTextP>
            In venenatis eu ligula vitae imperdiet. Nam in ornare mauris. Duis auctor a massa in
            mollis. Donec fermentum, quam a auctor cursus, nibh massa rhoncus dui, sagittis
            imperdiet magna justo quis augue. Curabitur malesuada felis ac neque suscipit
            pellentesque id ac lorem. Ut varius mauris arcu, nec fermentum quam blandit et. Aenean
            tortor nulla, ultricies sed blandit non, tincidunt nec sapien.
          </StyledInfoItemTextP>
        </StyledInfoItemText>
      </StyledInfoItem>
      <StyledFormH>The process is simple. Early access on roll</StyledFormH>
      <StyledForm>
        <TextField sx={{ height: '56px' }} placeholder='ie.zahid@gmail.com' name='email' />
        <Button variant='dark' onClick={() => console.log(1)}>
          Subscribe
        </Button>
      </StyledForm>
    </StyledModule>
  );
};

const StyledModule = styled.div`
  position: relative;
  background: url(${whiteBackImg});
  background-repeat: no-repeat;
  background-position: 0px 34px;
  background-size: 100%;
  @media (max-width: 1200px) {
    padding: 0 5%;
  }
`;

const StyledInfoHeader = styled.h3`
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  color: #0f236f;
  margin: 100px auto 30px;
  max-width: 600px;
  @media (max-width: 1000px) {
    margin: 40px auto 30px;
  }
`;
const StyledInfoP = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  max-width: 716px;
  margin: 40px auto 124px;
  color: #232323;
  @media (max-width: 1200px) {
    margin: 40px auto 20px;
  }
`;
const StyledInfoItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-top: 50px;
  @media (max-width: 1200px) {
    margin: 50px 0 !important;
    align-items: center;
    & > img {
      display: none;
    }
  }
  @media (max-width: 1000px) {
    margin: 20px 0 !important;
  }
`;
const StyledInfoItemText = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-width: 500px;
  margin: 60px 50px 0 0;
  @media (max-width: 1200px) {
    align-items: center;
    margin: 0 !important;
  }
`;
const StyledInfoItemTextH = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 35px;
  font-weight: 500;
  width: 450px;
  font-size: 24px;
  line-height: 26px;
  color: #15319c;
  & > img {
    margin-right: 20px;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
`;
const StyledInfoItemTextP = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #232323;
`;

const StyledRight = styled.img`
  position: absolute;
  left: 52%;
  width: 22%;
  top: 32%;
  display: none;
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    display: block;
  }
`;
const StyledLeft = styled.img`
  position: absolute;
  left: 30%;
  top: 56%;
  width: 25%;
  display: none;
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    display: block;
  }
`;
const StyledFormH = styled.div`
  font-weight: 500;
  margin: 84px auto 38px;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #000000;
`;
const StyledForm = styled.div`
  margin-top: 20px;
  margin-bottom: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > .MuiFormControl-root.MuiTextField-root {
    width: 440px;
    background: #ffffff;
    border-radius: 5px;
    margin-right: 10px;
  }
  & > .MuiButtonBase-root {
    width: 134px;
    height: 56px;
    font-size: 18px;
  }
  @media (max-width: 1000px) {
    margin-bottom: 50px;
  }
`;
