import { Box } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

export const SlicesInfo: FC = () => {
  return (
    <StyledContainer>
      <StyledBox>
        <StyledHeader>
          <StyledRound />
          Link Income Account
        </StyledHeader>
        <StyledBody>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis metus ante, porttitor et erat interdum,</StyledBody>
      </StyledBox>
      <StyledBox>
        <StyledHeader>
          <StyledRound />
          Create Slice
        </StyledHeader>
        <StyledBody>Nunc quis fringilla ex, rutrum euismod orci. Maecenas sed orci non nisl mollis dignissim vitae ac nunc.</StyledBody>
      </StyledBox>
      <StyledBox>
        <StyledHeader>
          <StyledRound />
          Allocate Money
        </StyledHeader>
        <StyledBody>Morbi ac mauris at diam pellentesque pretium sed volutpat arcu.</StyledBody>
      </StyledBox>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  background: #f8fafc;
  overflow: hidden;
  min-height: 137px;
  padding: 10px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledBox = styled(Box)`
  width: 33%;
  margin: 3px;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

const StyledHeader = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  height: 18px;
  color: #1c3fc8;
  display: flex;
  align-items: center;
  margin: 10px;
`;

const StyledRound = styled.div`
  width: 16px;
  height: 16px;
  background: #d5ddf9;
  border: 2px solid #3458e3;
  border-radius: 50%;
  margin-right: 10px;
`;

const StyledBody = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #475569;
  margin: 10px;
`;
