import { useState } from 'react';

const useRequest = (
  request: () => Promise<any>
): [any, boolean, any, () => Promise<any>] => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState('');
  const handleRequest = (): Promise<any> => {
    setIsLoading(true);
    return request()
      .then((response) => setData(response.data))
      .catch((e) => setError(e))
      .finally(() => setIsLoading(false));
  };

  return [data, isLoading, error, handleRequest];
};

export default useRequest;
