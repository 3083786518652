import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Service from '../../../API/service';
import { useActions } from '../../../hooks/useActions';
import useRequest from '../../../hooks/useRequest';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { ISubscription } from '../../../models/ISubscription';
import { Loader } from '../../UI/Loader';

interface Props {
  active: boolean;
  subscription: ISubscription;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export const SubscriptionCard: FC<Props> = ({
  subscription,
  active,
  isLoading,
  setIsLoading,
}) => {
  const dispatch = useDispatch();
  const { setUserSubscription } = useActions();
  const { enqueueSnackbar } = useSnackbar();
  const [, isSubLoading, subError, subHandler] = useRequest(() =>
    Service.subscribe(subscription.id)
  );
  const [, isUnsubLoading, unsubError, unsubHandler] = useRequest(
    Service.unsubscribe
  );

  function handleSubscribe() {
    if (active) {
      enqueueSnackbar(
        `You are already subscribed to the ${subscription.name}`,
        {
          variant: 'warning',
        }
      );
      return;
    }
    setIsLoading(true);
    subHandler()
      .then(() => {
        dispatch(setUserSubscription(subscription));
      })
      .finally(() => setIsLoading(false));
  }

  function handleUnsubscribe() {
    setIsLoading(true);
    unsubHandler()
      .then(() => {
        dispatch(setUserSubscription(null));
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    if (subError) {
      enqueueSnackbar(`Failed to subscribe to the ${subscription.name}`, {
        variant: 'error',
      });
    }
    if (unsubError) {
      enqueueSnackbar(`Failed to unsubscribe to the ${subscription.name}`, {
        variant: 'error',
      });
    }
  }, [subError, unsubError]);

  return (
    <SubscriptionCardContainer key={subscription.id} active={active}>
      <CardHeader>
        <SubscribeButton
          color={active ? 'primary' : 'secondary'}
          onClick={handleSubscribe}
          disabled={isLoading || isSubLoading || isUnsubLoading}
        >
          {isSubLoading || isUnsubLoading ? (
            <Loader width="25px" />
          ) : (
            subscription.name
          )}
        </SubscribeButton>
        {active && (
          <UnsubscribeButton
            disabled={isLoading || isSubLoading || isUnsubLoading}
            onClick={handleUnsubscribe}
            variant="text"
          >
            Cancel Subscription
          </UnsubscribeButton>
        )}
      </CardHeader>
      <Price>
        <>
          ${subscription.price}/<small>mo</small>
        </>
      </Price>
      <Benefits active={active}>
        <li>
          Connect upto <b>{subscription.max_accounts}</b> accounts
        </li>
        <li>
          {subscription.transfer
            ? 'Can transfer money'
            : 'Can not transfer money'}
        </li>
      </Benefits>
    </SubscriptionCardContainer>
  );
};

interface Activable {
  active: boolean;
}

const SubscriptionCardContainer = styled.div<Activable>`
  margin: 8px 0;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 415px;
  box-sizing: border-box;

  border: solid 1px ${({ active }) => (active ? '#adbcf4' : '#E2E8F0')};
  background-color: ${({ active }) => (active ? '#eaedfc' : '#FFFFFF')};
  ${({ active }) => active && 'color: #15319C'}
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubscribeButton = styled(Button)`
  &.MuiButton-root {
    min-width: 90px;
  }
  &.MuiButton-root.MuiButton-textSecondary {
    background-color: #e2e8f0;
    color: #334155;
  }
  &.MuiButton-root.MuiButton-textPrimary {
    background-color: #1c3fc8;
    color: #f8fafc;
  }
`;
const UnsubscribeButton = styled(Button)`
  &.MuiButton-root {
    background: none;
    color: #475569;
  }
  &.MuiButton-root:hover {
    background: none;
    color: #475569;
  }
`;

const Price = styled.div`
  font-size: 28px;
  margin-top: 10px;
  small {
    font-size: 18px;
  }
`;

const Benefits = styled.ul<Activable>`
  margin: 10px 0 0 0;
  padding: 0;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  li {
    padding-left: 16px;
    margin-left: 20px;
    list-style-type: none;
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='10' viewBox='0 0 9 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='4.5' cy='5' r='4.5'
      fill='%23${({ active }) =>
      active ? '3458E3' : 'D9D9D9'}'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center left;
  }

  li:first-child {
    margin-left: 0;
  }
`;
