import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import DataSaverOffIcon from '@mui/icons-material/DataSaverOff';
import logo from '../../assets/images/logo.png';
import { ContactModal } from './ContactModal';
import { FeatureRequestModal } from './FeatureRequestModal';
import AccountCircle from '@mui/icons-material/AccountCircle';

export const LeftBar: FC<{}> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [featureRequest, setFeatureRequest] = useState(false);
  const [contact, setContact] = useState(false);

  return (
    <>
      <StyledToolbar>
        <img src={logo} alt="logo" />
      </StyledToolbar>
      <List>
        {[
          {
            text: 'Dashboard',
            className: location.pathname === '/dashboard' && 'active',
            icon: <DashboardIcon />,
            onClick: () => {
              navigate(`/dashboard`);
            }
          },
          {
            text: 'Slices',
            className: location.pathname === '/slices' && 'active',
            icon: <DataSaverOffIcon />,
            onClick: () => {
              navigate(`/slices`);
            }
          },
          {
            text: 'Profile',
            className: location.pathname === '/profile' && 'active',
            icon: <AccountCircle />,
            onClick: () => {
              navigate(`/profile`);
            }
          },
          {
            text: 'Feature Request',
            icon: <SyncAltIcon />,
            onClick: () => {
              setFeatureRequest(true);
            }
          },
          {
            text: 'Contact',
            icon: <PhoneInTalkIcon />,
            onClick: () => {
              setContact(true);
            }
          }
        ].map((props, index) => (
          <StyledListItem key={props.text}>
            <ListItemButton onClick={props.onClick} className={props.className || ''}>
              <ListItemIcon>{props.icon}</ListItemIcon>
              <ListItemText primary={props.text} />
            </ListItemButton>
          </StyledListItem>
        ))}
      </List>
      {featureRequest && <FeatureRequestModal setVisible={setFeatureRequest} />}
      {contact && <ContactModal setVisible={setContact} />}
    </>
  );
};

const StyledListItem = styled(ListItem)`
  &.MuiListItem-root {
    width: 188px;
    font-style: normal;
    font-weight: 500;
    margin: 7px auto;
    padding: 5px 10px;
    color: #64748b;
  }

  & .MuiButtonBase-root {
    border-radius: 8px;
    padding-right: 5px;
    &.active {
      background-color: #3458e3;
      & .MuiListItemIcon-root,
      & .MuiTypography-root {
        color: #fff;
      }
    }
  }
  & .MuiTypography-root {
    font-size: 14px;
    line-height: 18px;
  }
  & .MuiListItemIcon-root {
    min-width: 28px;
    margin-left: 2px;
  }
  & .MuiSvgIcon-root {
    width: 18px;
  }
`;

const StyledToolbar = styled(Toolbar)`
  &.MuiToolbar-root {
    min-height: auto;
    height: 104px;
    margin: 0;
    padding: 0;
  }
  & img {
    margin-left: 0;
    margin-top: 0;
  }
`;
