import { Dispatch } from 'react';
import {
  SetIsLoadingAction,
  SetTransactionAction,
  TransactionAction,
  TransactionActionsEnum,
} from '../reducers/transaction/types';
import { setIsLoading } from './dwolla';

export const setTransactionAmount = (amount: number): SetTransactionAction => {
  return {
    type: TransactionActionsEnum.SET_TRANSACTION,
    payload: amount,
  };
};

export const setIsTransactionLoading = (
  isLoading: boolean
): SetIsLoadingAction => {
  return {
    type: TransactionActionsEnum.SET_IS_LOADING,
    payload: isLoading,
  };
};

export const setTransaction = (amount: number) => {
  return async (dispatch: Dispatch<TransactionAction>) => {
    dispatch(setIsTransactionLoading(true));
    dispatch(setTransactionAmount(amount));
  };
};

export const setIsTransactionLoaded = () => {
  return async (dispatch: Dispatch<TransactionAction>) => {
    dispatch(setIsTransactionLoading(false));
  };
};
