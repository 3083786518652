let idleTime = 0;
let idleInterval: any = null;

const resetIdleTime = () => {
    idleTime = 0;
};

const detectIdleTime = (onIdleTimeout: any) => {
    idleTime++;
    if (idleTime >= 5) {
        clearInterval(idleInterval)
        onIdleTimeout();
    }
};

const startIdleTimeout = (onIdleTimeout: any) => {
    idleInterval = setInterval(() => detectIdleTime(onIdleTimeout), 100000);

    window.addEventListener('click', resetIdleTime);
    window.addEventListener('mousemove', resetIdleTime);
    window.addEventListener('keydown', resetIdleTime);
};

export default startIdleTimeout;