import React, { FC } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

interface Props {
  embedId: string;
  setVisible: (status: boolean) => void;
}

export const SlicesVideoModal: FC<Props> = ({ embedId, setVisible }) => {
  return (
    <StyledModal onClick={() => setVisible(false)} open={true} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <StyledBox onClick={(e) => e.stopPropagation()}>
        <Styledh3>How Slicing works</Styledh3>
        <iframe
          width="445"
          height="270"
          src={`https://www.youtube.com/embed/${embedId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </StyledBox>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  user-select: none;
  & .MuiBackdrop-root {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const StyledBox = styled(Box)`
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: 0px 12px 33.4221px rgba(21, 49, 156, 0.1);
  max-width: 512px;
  padding: 5px 35px;
  border-radius: 8px;
  width: 90%;
  margin: 0 auto;
  height: 394px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  & > * {
    width: 100%;
  }
  & iframe {
    border-radius: 10px;
    margin-top: 10px;
  }
`;

const Styledh3 = styled.h3`
  text-align: left;
`;
