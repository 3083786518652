import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Navigate, Routes, Route } from 'react-router-dom';
import { privateRoutes, publicRoutes, RouteNames } from '../router';
import { useActions } from '../hooks/useActions';
import { AuthContext } from '../context';
import { Loader } from '../components/UI/Loader';
import { useSelector } from 'react-redux';
import { useTypedSelector } from '../hooks/useTypedSelector';

export const AppRouter = () => {
  const { isAuth, isLoading, user } = useTypedSelector(state => state.auth);
  const { setIsAuth, loadUser } = useActions();
  const syncRef = useRef(false);

  useEffect(() => {
    if (syncRef.current) {
      return;
    }
    syncRef.current = true;
    loadUser();
  }, []);

  useEffect(() => {
    if (user.id) {
      setIsAuth(true);
    }
  }, [user]);

  if (isLoading) {
    return (
      <StyledLoader>
        <Loader />
      </StyledLoader>
    );
  }

  return isAuth ? (
    <Routes>
      {privateRoutes.map(route => (
        <Route key={route.path} element={<route.component />} path={route.path} />
      ))}
      <Route path='*' element={<Navigate to={RouteNames.DASHBOARD} />} />
    </Routes>
  ) : (
    <Routes>
      {publicRoutes.map(route => (
        <Route key={route.path} element={<route.component />} path={route.path} />
      ))}
      <Route path='*' element={<Navigate to={RouteNames.LANDING} />} />
    </Routes>
  );
};

const StyledLoader = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`;
