import { IBankAccount, IBankAccountDeleteMessage } from '../../../models/IBankAccount';

export interface BankAccountsState {
  accounts: IBankAccount[];
  isLoading: boolean;
  defaultAccount: IBankAccount;
  error: string;
  defaultAccountError: string;
  deleteAccountMessage: IBankAccountDeleteMessage | null;
}

export enum BankAccountActionsEnum {
  SET_BANK_ACCOUNTS = 'SET_BANK_ACCOUNTS',
  SET_DEFAULT_ACCOUNT = 'SET_DEFAULT_ACCOUNT',
  SET_IS_LOADING = 'SET_IS_LOADING_BANK_ACCOUNTS',
  SET_ERROR = 'SET_ERROR_BANK_ACCOUNTS',
  SET_DEFAULT_ACCOUNT_ERROR = 'SET_DEFAULT_ACCOUNT_ERROR',
  SET_DELETE_ACCOUNT_MESSAGE = 'SET_DELETE_ACCOUNT_MESSAGE'
}

export interface SetBankAccountAction {
  type: BankAccountActionsEnum.SET_BANK_ACCOUNTS;
  payload: IBankAccount[];
}
export interface SetDefaultAccountAction {
  type: BankAccountActionsEnum.SET_DEFAULT_ACCOUNT;
  payload: IBankAccount;
}
export interface SetIsLoadingAction {
  type: BankAccountActionsEnum.SET_IS_LOADING;
  payload: boolean;
}

export interface SetErrorAction {
  type: BankAccountActionsEnum.SET_ERROR;
  payload: string;
}

export interface SetErrorAction {
  type: BankAccountActionsEnum.SET_ERROR;
  payload: string;
}

export interface SetDeleteAccountMessageAction {
  type: BankAccountActionsEnum.SET_DELETE_ACCOUNT_MESSAGE;
  payload: IBankAccountDeleteMessage | null;
}

export interface SetDefaultAccountErrorAction {
  type: BankAccountActionsEnum.SET_DEFAULT_ACCOUNT_ERROR;
  payload: string;
}

export type BankAccountAction =
  | SetBankAccountAction
  | SetDefaultAccountAction
  | SetIsLoadingAction
  | SetErrorAction
  | SetDeleteAccountMessageAction
  | SetDefaultAccountErrorAction;
