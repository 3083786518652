import { FC } from 'react';
import styled from 'styled-components';
import photo1 from '../../../assets/images/landing/photo1.png';
import photo2 from '../../../assets/images/landing/photo2.png';
import apostropheImg from '../../../assets/images/landing/apostrophe.svg';

interface Props {}

export const Review: FC<Props> = ({}) => {
  const list = [
    {
      img: photo1,
      name: 'John Blake',
      text: 'Aliquam non nisi a ipsum aliquam eleifend. Maecenas in sapien eget tortor posuere vulputate nec vitae dolor. Cras nec orci massa. Vivamus in lectus non dolor porta ornare. '
    },
    {
      img: photo2,
      name: 'John Blake2',
      text: 'Aliquam non nisi a ipsum aliquam eleifend. Maecenas in sapien eget tortor posuere vulputate nec vitae dolor. Cras nec orci massa. Vivamus in lectus non dolor porta ornare. '
    },
    {
      img: photo2,
      name: 'John Blake 3',
      text: 'Aliquam non nisi a ipsum aliquam eleifend. Maecenas in sapien eget tortor posuere vulputate nec vitae dolor. Cras nec orci massa. Vivamus in lectus non dolor porta ornare. '
    }
  ];
  return (
    <StyledModule>
      <h1>People Loved us</h1>
      <StyledReview>
        {list.map((item, i) => (
          <StyledReviewItem key={i}>
            <img src={item.img} alt='photo' />
            <StyledReviewComment>
              <img src={apostropheImg} alt='apostrophe' />
              <p>{item.text}</p>
              <p>{item.name}</p>
            </StyledReviewComment>
          </StyledReviewItem>
        ))}
      </StyledReview>
    </StyledModule>
  );
};

const StyledModule = styled.div`
  min-height: 527px;
  & > h1 {
    font-weight: 600;
    font-size: 36px;
    line-height: 40px;
    color: #0f236f;
    margin: 75px 0 100px 0;
    padding-left: 140px;
  }
  @media (max-width: 1200px) {
    min-height: auto;
    & > h1 {
      margin: 25px 0 50px 0;
      padding-left: 5%;
      font-size: 30px;
      line-height: 36px;
    }
  }
`;

const StyledReview = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  overflow-x: auto;
  margin: 20px;
  @media (max-width: 1000px) {
    margin: 0px;
  }
`;

const StyledReviewItem = styled.div`
  flex: 0 0 auto;
  max-width: 100%;
  min-height: 200px;
  padding: 0 150px 30px;
  width: 645px;
  border-right: 1px solid #3357e2;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 5%;
  &:last-child {
    border-right: none;
  }
  & > img {
    border-radius: 50%;
    width: 100px;
    margin-right: 20px;
  }

  @media (max-width: 1200px) {
    max-width: none;
    min-height: 200px;
    width: calc(100% - 5%);
    padding: 0 5%;
    & > img {
      width: 50px;
    }
  }
`;

const StyledReviewComment = styled.div`
  margin: 0 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  & > img {
  }
  & > :nth-child(2) {
    width: 600px;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #0f236f;
  }
  & > :nth-child(3) {
    font-weight: 600;
  }
  @media (max-width: 1200px) {
    width: 100%;
    & > img {
      width: 20px;
    }
    & > :nth-child(2) {
      width: 100%;
    }
  }
`;
