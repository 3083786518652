import { Dispatch } from 'react';
import Service from '../../API/service';
import { IBankAccount, IBankAccountDeleteMessage } from '../../models/IBankAccount';
import {
  BankAccountAction,
  BankAccountActionsEnum,
  SetBankAccountAction,
  SetDefaultAccountAction,
  SetDefaultAccountErrorAction,
  SetDeleteAccountMessageAction,
  SetErrorAction,
  SetIsLoadingAction
} from '../reducers/bankAccounts/types';

export const setBankAccounts = (bankAccounts: IBankAccount[]): SetBankAccountAction => {
  return {
    type: BankAccountActionsEnum.SET_BANK_ACCOUNTS,
    payload: bankAccounts
  };
};

export const setDefaultAccount = (bankAccount: IBankAccount): SetDefaultAccountAction => {
  return {
    type: BankAccountActionsEnum.SET_DEFAULT_ACCOUNT,
    payload: bankAccount
  };
};

export const setIsBankAccountsLoading = (payload: boolean): SetIsLoadingAction => {
  return {
    type: BankAccountActionsEnum.SET_IS_LOADING,
    payload: payload
  };
};

export const setAccountError = (payload: string): SetErrorAction => {
  return {
    type: BankAccountActionsEnum.SET_ERROR,
    payload: payload
  };
};

export const setDeleteAccountMessage = (
  payload: IBankAccountDeleteMessage | null
): SetDeleteAccountMessageAction => {
  return {
    type: BankAccountActionsEnum.SET_DELETE_ACCOUNT_MESSAGE,
    payload: payload
  };
};

export const setDefaultAccountError = (payload: string): SetDefaultAccountErrorAction => {
  return {
    type: BankAccountActionsEnum.SET_DEFAULT_ACCOUNT_ERROR,
    payload: payload
  };
};

export const loadBankAccounts = () => {
  return async (dispatch: Dispatch<BankAccountAction>) => {
    try {
      dispatch(setIsBankAccountsLoading(true));
      const response: any = await Service.bankAccounts();
      if (response?.data) {
        const accounts: IBankAccount[] = response.data.map((account: Object, index: number) => ({
          ...account,
          index
        }));
        const defaultAccount = accounts.find(account => account.default);
        if (defaultAccount) {
          dispatch(setDefaultAccount(defaultAccount));
        }
        dispatch(setBankAccounts(accounts));
      }
    } finally {
      dispatch(setIsBankAccountsLoading(false));
    }
  };
};

export const setDefaultBankAccount = (dwolla_url: string) => {
  return async (dispatch: Dispatch<BankAccountAction>, getState: any) => {
    const errorMessage = 'Something went wrong. Please try again later';
    try {
      const { accounts } = getState().bankAccounts;
      dispatch(setIsBankAccountsLoading(true));
      const response: any = await Service.setDefaultBankAccount(dwolla_url);
      if (response?.data?.status === 'ok') {
        const defaultAccount = accounts.find(
          (account: IBankAccount) => account.dwolla_url === dwolla_url
        );
        if (defaultAccount) {
          dispatch(setDefaultAccount(defaultAccount));
        }
      } else {
        dispatch(setDefaultAccountError(''));
      }
    } catch (e: any) {
      if (e?.response?.status === 422) {
        dispatch(setDefaultAccountError(e?.response?.data?.errorMessage ?? errorMessage));
      } else {
        dispatch(setDefaultAccountError(errorMessage));
      }
    } finally {
      dispatch(setIsBankAccountsLoading(false));
    }
  };
};

export const deleteBankAccount = (dwolla_url: string) => {
  return async (dispatch: Dispatch<BankAccountAction>, getState: any) => {
    const errorMessage = 'Something went wrong. Please try again later';

    dispatch(setDeleteAccountMessage(null));

    try {
      const response: any = await Service.deleteBankAccount({ dwolla_url });
      if (response?.status === 200) {
        const { accounts } = getState().bankAccounts;

        dispatch(
          setBankAccounts([
            ...accounts.filter((account: IBankAccount) => account.dwolla_url !== dwolla_url)
          ])
        );

        dispatch(
          setDeleteAccountMessage({
            status: 'success',
            text: 'Bank account deleted',
            dwolla_url: dwolla_url
          })
        );
      } else {
        dispatch(
          setDeleteAccountMessage({
            status: 'error',
            text: errorMessage,
            dwolla_url: dwolla_url
          })
        );
      }
    } catch (e: any) {
      dispatch(
        setDeleteAccountMessage({
          status: 'error',
          text: errorMessage,
          dwolla_url: dwolla_url
        })
      );
    } finally {
    }
  };
};
