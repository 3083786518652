import { Box, Grid, Paper } from '@mui/material';
import { FC, useState } from 'react';
import styled from 'styled-components';
import PageFrame from '../components/parts/PageFrame';
import { SlicesInfo } from '../components/parts/SlicesInfo';
import { SlicesVideoModal } from '../components/parts/SlicesVideoModal';
import { SlicesIncomeAccount } from '../components/parts/SlicesIncomeAccount';
import { SlicesBankAccounts } from '../components/parts/SlicesBankAccounts';
import PlayYoutubeIcon from '../assets/images/play_youtube.svg';
import { SlicesList } from '../components/parts/SlicesList';

export const Slices: FC = () => {
  const [showYoutubeModal, setShowYoutubeModal] = useState(false);
  const youtubeVideoId = 'XYO1wah3KNc';

  return (
    <PageFrame>
      <StyledH>Slices</StyledH>
      <Grid container spacing={{ xl: 3, xs: 2 }}>
        <Grid item xs={12} md={12} lg={3}>
          <TopStyledItem>
            <SlicesIncomeAccount />
          </TopStyledItem>
        </Grid>
        <StyledGrid item xs={12} md={12} lg={6}>
          <TopStyledItem>
            <SlicesInfo />
          </TopStyledItem>
        </StyledGrid>
        <StyledGrid item xs={12} md={12} lg={3}>
          <TopStyledItem>
            <StyledYoutube embedid={youtubeVideoId} onClick={() => setShowYoutubeModal(true)}>
              <h3>A video might help you to understand</h3>
              <img src={PlayYoutubeIcon} alt="play youtube icon" />
            </StyledYoutube>
          </TopStyledItem>
        </StyledGrid>
      </Grid>
      <Grid container spacing={{ xl: 3, xs: 2 }}>
        <Grid item xs={12} md={12} lg={9}>
          <BottomStyledItem sx={{ background: 'transparent' }}>
            <SlicesList />
          </BottomStyledItem>
        </Grid>
        <StyledGrid item xs={12} md={12} lg={3}>
          <BottomStyledItem sx={{ background: '#fff', border: '1px solid #e2e8f0' }}>
            <SlicesBankAccounts />
          </BottomStyledItem>
        </StyledGrid>
      </Grid>
      {showYoutubeModal && <SlicesVideoModal embedId={youtubeVideoId} setVisible={setShowYoutubeModal} />}
    </PageFrame>
  );
};

const StyledH = styled.h2`
  margin: 0 0 15px;
`;

const TopStyledItem = styled(Paper)`
  &.MuiPaper-root {
    background-color: #fff;
    margin-bottom: 18px;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: none;
    min-height: 137px;
    height: calc(100% - 18px);
  }
`;

const BottomStyledItem = styled(Paper)`
  &.MuiPaper-root {
    margin-bottom: 18px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: none;
    height: calc(100% - 18px);
  }
`;

const StyledYoutube = styled(Box)<{ embedid: string }>`
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  min-height: 137px;
  background: ${({ embedid }) => `url(https://i.ytimg.com/vi/${embedid}/mqdefault.jpg), #000` || '#fff'};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  & h3 {
    font-weight: normal;
    margin: 8px 0 15px;
    font-size: 14px;
    color: #ffffff;
    line-height: 16px;
    text-align: center;
  }
`;

const StyledGrid = styled(Grid)`
  @media (max-width: 1199px) {
    &.MuiGrid-root {
      padding-top: 0 !important;
    }
  }
`;
