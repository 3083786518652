import { IUser } from '../../../models/IUser';
import { AuthState, AuthAction, AuthActionsEnum } from './types';

const initialState: AuthState = {
  isAuth: false,
  user: {} as IUser,
  isLoading: true,
  error: '',
  validationError: '',
  successMessage: ''
};

export default function authReducer(state = initialState, action: AuthAction): AuthState {
  switch (action.type) {
    case AuthActionsEnum.SET_AUTH:
      return {
        ...state,
        isAuth: action.payload,
        isLoading: false,
        error: '',
        validationError: ''
      };
    case AuthActionsEnum.SET_USER:
      return { ...state, user: action.payload, isLoading: false, isAuth: true };
    case AuthActionsEnum.SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case AuthActionsEnum.SET_ERROR:
      return { ...state, error: action.payload, isLoading: false };
    case AuthActionsEnum.SET_VALIDATION_ERROR:
      return { ...state, validationError: action.payload, isLoading: false };
    case AuthActionsEnum.SET_USER_SUCCESS_MESSAGE:
      return { ...state, successMessage: action.payload, isLoading: false };
    case AuthActionsEnum.SET_USER_SUBSCRIPTION:
      return {
        ...state,
        user: { ...state.user, subscription: action.payload }
      };
    default:
      return state;
  }
}
