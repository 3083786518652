import React, { FC } from 'react';
import styled from 'styled-components';
import PageFrame from '../components/parts/PageFrame';
import { AccountInformation } from '../components/parts/AccountInformation';

export const Profile: FC<{}> = () => {
  return (
    <PageFrame>
      <StyledH>Profile</StyledH>
      <AccountInformation />
    </PageFrame>
  );
};

const StyledH = styled.h2`
  margin: 0 0 15px;
`;
