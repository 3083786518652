import { IBankAccount, IBankAccountDeleteMessage } from '../../../models/IBankAccount';
import { BankAccountAction, BankAccountActionsEnum, BankAccountsState } from './types';

const initialState: BankAccountsState = {
  isLoading: false,
  accounts: [],
  defaultAccount: {} as IBankAccount,
  error: '',
  defaultAccountError: '',
  deleteAccountMessage: null
};

export default function bankAccountsReducer(
  state = initialState,
  action: BankAccountAction
): BankAccountsState {
  switch (action.type) {
    case BankAccountActionsEnum.SET_BANK_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
        isLoading: false
      };
    case BankAccountActionsEnum.SET_DEFAULT_ACCOUNT:
      return {
        ...state,
        defaultAccount: action.payload,
        isLoading: false
      };
    case BankAccountActionsEnum.SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case BankAccountActionsEnum.SET_ERROR:
      return { ...state, error: action.payload, isLoading: false };
    case BankAccountActionsEnum.SET_DELETE_ACCOUNT_MESSAGE:
      return {
        ...state,
        deleteAccountMessage: action.payload,
        isLoading: false
      };
    case BankAccountActionsEnum.SET_DEFAULT_ACCOUNT_ERROR:
      return {
        ...state,
        defaultAccountError: action.payload,
        isLoading: false
      };
    default:
      return state;
  }
}
