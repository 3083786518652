import React, { FormEvent, useState, FC } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import logo from '../../assets/images/logo.svg';
import authImg from '../../assets/images/auth_img.png';
import { useActions } from './../../hooks/useActions';
import Service from '../../API/service';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { Loader } from '../UI/Loader';
import { StyledAlert } from './StyledAlert';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

interface Props {
  setVisible: (value: boolean) => void;
}

export const AuthModal: FC<Props> = ({ setVisible }) => {
  const { register } = useActions();
  const { isLoading, error, validationError, successMessage } = useTypedSelector(
    state => state.auth
  );
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [password, setPassword] = useState('');
  const [checkedTerms, setCheckedTerms] = useState(true);

  function sendForm(e: FormEvent) {
    e.preventDefault();
    register({
      email: email,
      given_name: firstName,
      family_name: lastName,
      password: password
    });
  }

  return (
    <StyledModal
      open={true}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      onClick={() => setVisible(false)}>
      <>
        <StyledImg src={logo} alt='logo' />
        <StyledAuthBox>Powered by Auth0</StyledAuthBox>
        <StyledBox onClick={e => e.stopPropagation()}>
          <div>
            <h4>JOIN US</h4>
            <h2>Make your profit shine</h2>
            {!successMessage && (
              <form onSubmit={sendForm}>
                <TextField
                  value={email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                  label='Email'
                  type='email'
                  required
                />
                <TextField
                  value={firstName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFirstName(e.target.value)
                  }
                  label='First Name'
                  required
                />
                <TextField
                  value={lastName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
                  label='Last Name'
                  required
                />
                <TextField
                  type='password'
                  value={password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                  label='Password'
                  required
                />
                <div className='error'>{validationError}</div>
                <FormGroup sx={{ p: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={true}
                        onChange={e => setCheckedTerms(e.target.checked)}
                      />
                    }
                    label={
                      <div>
                        By checking this box you agree to{' '}
                        <Link href={process.env.PUBLIC_URL + '/terms.html'} target='_blank'>
                          Our Terms of Service
                        </Link>{' '}
                        and{' '}
                        <Link href={process.env.PUBLIC_URL + '/privacy.html'} target='_blank'>
                          Privacy Policy
                        </Link>{' '}
                        as well as our Vendor{' '}
                        <Link
                          href='https://www.termsfeed.com/live/f96dd647-1bb1-45b9-a54a-076e39282455'
                          target='_blank'>
                          Dwolla’s Terms of Service
                        </Link>{' '}
                        and{' '}
                        <Link
                          href='https://www.termsfeed.com/live/1474a590-00b6-4186-97d7-14b26f62e6a2'
                          target='_blank'>
                          Privacy Policy
                        </Link>
                      </div>
                    }
                  />
                </FormGroup>
                <Button
                  type='submit'
                  disabled={isLoading || checkedTerms === false ? true : false}
                  className='active'>
                  {isLoading ? (
                    <Loader color='#fff' width='25px' height='25px' />
                  ) : (
                    <span>Sign Up</span>
                  )}
                </Button>
              </form>
            )}
            <div className='login-here'>
              {!successMessage && 'Already have an account?'}
              <Link
                sx={{ p: 1, textDecoration: 'none' }}
                component='button'
                onClick={() => Service.login()}>
                Login Here
              </Link>
            </div>
          </div>
          <div></div>
        </StyledBox>
      </>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  user-select: none;
  & .MuiBackdrop-root {
    background: #f0f5f9;
  }
  @media (max-width: 650px) {
    & .MuiBackdrop-root {
      background: #fff;
    }
  }
`;

const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: 0px 12px 33.4221px rgba(21, 49, 156, 0.1);
  border-radius: 20px;
  max-width: 1152px;
  width: 96%;
  margin: 0 auto;
  height: 80%;
  max-height: 650px;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  @media (max-width: 650px) {
    width: 100%;
    border-radius: 0;
    box-shadow: none;
  }
  > * {
    @media (max-width: 650px) {
      width: 100%;
    }
    width: 50%;
    height: 100%;
  }
  > :nth-child(1) {
    & form {
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;
      max-width: 380px;
    }
    & form > * {
      width: 100%;
    }
    & h4 {
      margin: 30px 0 0;
      color: #475569;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
    }
    & h2 {
      margin: 10px 0;
      color: #1e293b;
      font-weight: 600;
      font-size: 28px;
      text-align: center;
    }
    & .MuiFormControl-root {
      margin-top: 20px;
    }
    & .MuiButtonBase-root {
      margin-top: 0;
    }
    & .MuiFormGroup-root {
      margin: 10px 0;
    }
    & .MuiButtonBase-root.MuiCheckbox-root {
      margin-top: 0;
    }
    & .MuiTypography-root {
      font-size: 12px;
    }
    & form .MuiButtonBase-root {
      min-height: 56px;
      height: 56px;
      font-size: 20px;
    }
    & .error {
      margin-top: 10px;
      font-size: 14px;
      color: #cc2828;
      text-align: center;
    }
    & > div.login-here {
      margin-top: 5px;
      font-size: 14px;
      text-align: center;
      height: 30px;
    }
  }
  > :nth-child(2) {
    @media (max-width: 650px) {
      display: none;
    }
    background: url(${authImg});
    background-position: center;
    background-repeat: no-repeat;
    background-color: #16319d;
    background-size: 100%;
    position: sticky;
    top: 0;
  }
`;

const StyledImg = styled.img`
  position: absolute;
  top: 5%;
  left: 10%;
`;

const StyledAuthBox = styled(Box)`
  position: absolute;
  left: 0;
  bottom: 15px;
  color: #aaa;
  text-align: center;
  width: 100%;
`;
