import { ISubscription } from '../../../models/ISubscription';
import { IUser } from '../../../models/IUser';

export interface AuthState {
  isAuth: boolean;
  user: IUser;
  isLoading: boolean;
  error: string;
  validationError: string;
  successMessage: string;
}

export enum AuthActionsEnum {
  SET_AUTH = 'SET_AUTH',
  SET_USER = 'SET_USER',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_ERROR = 'SET_ERROR',
  USER_LOGOUT = 'USER_LOGOUT',
  SET_VALIDATION_ERROR = 'SET_USER_VALIDATION_ERROR',
  SET_USER_SUCCESS_MESSAGE = 'SET_USER_SUCCESS_MESSAGE',
  SET_USER_SUBSCRIPTION = 'SET_USER_SUBSCRIPTION,',
}

export interface SetAuthAction {
  type: AuthActionsEnum.SET_AUTH;
  payload: boolean;
}

export interface SetUserAction {
  type: AuthActionsEnum.SET_USER;
  payload: IUser;
}

export interface SetIsLoadingAction {
  type: AuthActionsEnum.SET_IS_LOADING;
  payload: boolean;
}

export interface SetErrorAction {
  type: AuthActionsEnum.SET_ERROR;
  payload: string;
}

export interface SetValidationErrorAction {
  type: AuthActionsEnum.SET_VALIDATION_ERROR;
  payload: string;
}

export interface SetSuccessMessageAction {
  type: AuthActionsEnum.SET_USER_SUCCESS_MESSAGE;
  payload: string;
}

export interface LogoutAction {
  type: AuthActionsEnum.USER_LOGOUT;
}

export interface SetUserSubscription {
  type: AuthActionsEnum.SET_USER_SUBSCRIPTION;
  payload: ISubscription | null;
}

export type AuthAction =
  | SetAuthAction
  | SetUserAction
  | SetIsLoadingAction
  | SetErrorAction
  | LogoutAction
  | SetValidationErrorAction
  | SetSuccessMessageAction
  | SetUserSubscription;
