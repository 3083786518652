import { createTheme } from '@mui/material/styles';
import EuclidCircularB from './assets/fonts/EuclidCircularB-Medium.ttf';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    dark: true;
    light: true;
  }
}

export const theme = createTheme({
  typography: {
    allVariants: {
      color: '#475569'
    },
    fontFamily: ['EuclidCircularB', 'sans-serif'].join(',')
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          color: '#64748B'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        standardInfo: {
          backgroundColor: '#fff',
          color: '#475569'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: '#475569'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#000'
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'light' },
          style: {
            background: '#3458E3',
            color: '#fff',
            borderRadius: '5px',
            '&:hover': {
              backgroundColor: '#3458E3',
              color: '#fff'
            },
            '&:disabled': {
              opacity: '0.6'
            }
          }
        },
        {
          props: { variant: 'dark' },
          style: {
            background: '#182B78',
            color: '#fff',
            borderRadius: '5px',
            '&:hover': {
              backgroundColor: '#0f1943',
              color: '#fff'
            },
            '&:disabled': {
              opacity: '0.6'
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          color: '#1c3fc8',
          backgroundColor: '#adbcf4',
          textTransform: 'none',
          '&:hover': {
            color: '#1c3fc8',
            backgroundColor: '#adbcf4',
            textTransform: 'none'
          },
          '&.active, &.active:hover': {
            backgroundColor: '#3458E3',
            color: '#fff'
          },
          '&:disabled': {
            opacity: '0.6'
          }
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'EuclidCircularB';
          font-style: normal;
          font-weight: 400;
          src: url(${EuclidCircularB}) format('truetype');
        }
      `
    }
  }
});
