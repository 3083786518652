export interface TransactionState {
  amount: number;
  isLoading: boolean;
}

export enum TransactionActionsEnum {
  SET_TRANSACTION = 'SET_TRANSACTION',
  SET_IS_LOADING = 'SET_IS_TRANSACTION_LOADING',
}

export interface SetTransactionAction {
  type: TransactionActionsEnum.SET_TRANSACTION;
  payload: number;
}

export interface SetIsLoadingAction {
  type: TransactionActionsEnum.SET_IS_LOADING;
  payload: boolean;
}

export type TransactionAction = SetTransactionAction | SetIsLoadingAction;
