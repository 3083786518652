import { IUser } from '../../models/IUser';
import {
  AuthAction,
  AuthActionsEnum,
  SetAuthAction,
  SetErrorAction,
  SetIsLoadingAction,
  SetUserAction,
  LogoutAction,
  SetValidationErrorAction,
  SetSuccessMessageAction,
  SetUserSubscription,
} from '../reducers/auth/types';
import { Dispatch } from 'react';
import Service from '../../API/service';
import { AxiosError } from 'axios';
import { loadDwollaCustomer } from './dwolla';
import { DwollaAction } from '../reducers/dwolla/types';
import { loadBankAccounts } from './bankAccounts';
import { BankAccountAction } from '../reducers/bankAccounts/types';
import { loadSlices } from './slices';
import { SlicesAction } from '../reducers/slices/types';
import { ISubscription } from '../../models/ISubscription';


export const setUser = (user: IUser): SetUserAction => {
  return {
    type: AuthActionsEnum.SET_USER,
    payload: user,
  };
};

export const setIsAuth = (auth: boolean): SetAuthAction => {
  return {
    type: AuthActionsEnum.SET_AUTH,
    payload: auth,
  };
};

export const setIsLoading = (payload: boolean): SetIsLoadingAction => {
  return {
    type: AuthActionsEnum.SET_IS_LOADING,
    payload: payload,
  };
};

export const setError = (payload: string): SetErrorAction => {
  return {
    type: AuthActionsEnum.SET_ERROR,
    payload: payload,
  };
};

export const setValidationError = (
  payload: string
): SetValidationErrorAction => {
  return {
    type: AuthActionsEnum.SET_VALIDATION_ERROR,
    payload: payload,
  };
};

export const setUserSuccessMessage = (
  payload: string
): SetSuccessMessageAction => {
  return {
    type: AuthActionsEnum.SET_USER_SUCCESS_MESSAGE,
    payload: payload,
  };
};

export const logout = (): LogoutAction => {
  return {
    type: AuthActionsEnum.USER_LOGOUT,
  };
};

export const setUserSubscription = (
  payload: ISubscription | null
): SetUserSubscription => {
  return {
    type: AuthActionsEnum.SET_USER_SUBSCRIPTION,
    payload: payload,
  };
};

export const register = (data: IUser) => {
  return async (
    dispatch: Dispatch<
      AuthAction | DwollaAction | BankAccountAction | SlicesAction
    >
  ) => {
    try {
      await Service.register(data);
      Service.login()
    } catch (e) {
        dispatch(
          setValidationError(
            ((e as AxiosError)?.response?.data as any)?.message ??
              'Invalid data. Please check form and try again'
          )
        );
    }
  };
};

export const loadUser = () => {
  return async (
    dispatch: Dispatch<
      AuthAction | DwollaAction | BankAccountAction | SlicesAction
    >
  ) => {
    try {
      dispatch(setIsLoading(true));
      const response: any = await Service.accountInfo();
      if (response?.data?.id ?? false) {
        dispatch(setUser(response.data));
        loadDwollaCustomer()(dispatch);
        loadBankAccounts()(dispatch);
        loadSlices()(dispatch);
      }
    } catch (e) {
      if ((e as AxiosError)?.response?.status === 401) {
        dispatch(setError('Please login or sign up'));
      } else {
        dispatch(setError('User loading error'));
      }
    }
  };
};
