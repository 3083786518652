import React from 'react';
import { Paper } from '@mui/material';
import styled from 'styled-components';

export const StyledItem = styled(Paper)`
&.MuiPaper-root {
  margin-bottom: 18px;
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: none;
}
`;
