import { SlicesAction, SlicesState, SlicesActionsEnum } from './types';

const initialState: SlicesState = {
  isLoading: false,
  slices: [],
  error: '',
};

export default function slicesReducer(
  state = initialState,
  action: SlicesAction
) {
  switch (action.type) {
    case SlicesActionsEnum.SET_SLICES:
      return { ...state, slices: action.payload, isLoading: false };
    case SlicesActionsEnum.SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case SlicesActionsEnum.SET_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
}
