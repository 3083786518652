import { Box, Button } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { IBankAccount, IBankAccountDeleteData } from '../../models/IBankAccount';
import { Loader } from '../UI/Loader';
import { useAddAccount } from '../../hooks/useAddAccount';
import { useActions } from '../../hooks/useActions';
import { useSnackbar } from 'notistack';

export const SlicesBankAccounts: FC = () => {
  const { isLoading, accounts, deleteAccountMessage } = useTypedSelector(
    state => state.bankAccounts
  );
  const { setDeleteAccountMessage } = useActions();
  const { open, ready } = useAddAccount();
  const { deleteBankAccount } = useActions();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [deleteAccountWaitList, setDeleteAccountWaitList] = useState<Array<string>>([]);

  useEffect(() => {
    if (deleteAccountMessage !== null) {
      enqueueSnackbar(deleteAccountMessage.text, {
        variant: deleteAccountMessage.status
      });

      if (deleteAccountMessage.status === 'error') {
        setDeleteAccountWaitList([
          ...deleteAccountWaitList.filter(
            (dwolla_url: string) => dwolla_url !== deleteAccountMessage.dwolla_url
          )
        ]);
      }
      setDeleteAccountMessage(null);
    }
  }, [deleteAccountMessage]);

  function addAccount() {
    open();
  }

  function deleteAccount(dwolla_url: string) {
    deleteBankAccount(dwolla_url);
    setDeleteAccountWaitList([...deleteAccountWaitList, dwolla_url]);
  }

  return (
    <StyledContainer>
      <h3>Bank Accounts</h3>
      <Button className='active' onClick={addAccount} disabled={!ready || isLoading}>
        {!ready || isLoading ? (
          <Loader color='#fff' width='25px' height='25px' />
        ) : (
          <>
            <AddCircleOutlineIcon />
            Add New Account
          </>
        )}
      </Button>
      <StyledBox>
        {accounts.map((account: IBankAccount, n) => (
          <StyledBoxAccount key={`account.dwolla_url${n}`}>
            <Box>
              <h3>{account.name}</h3>

              {deleteAccountWaitList.includes(account.dwolla_url) ? (
                <StyledLoader>
                  <Loader width='1em' height='1em' color='#aaa' />
                </StyledLoader>
              ) : (
                <ClearIcon onClick={() => deleteAccount(account.dwolla_url)} />
              )}
            </Box>
            <Box>
              <h3>
                {account.bankName}******{account.last4}
              </h3>
              {/* not assigned to slices */}
              {account.assigned && <Box>Unassigned</Box>}
            </Box>
          </StyledBoxAccount>
        ))}
      </StyledBox>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  user-select: none;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  border-top: 4px solid #1c3fc8;
  padding: 10px;
  border-radius: 5px;
  & > h3 {
    font-size: 18px;
    color: #334155;
  }
  & .MuiSvgIcon-root {
    margin-right: 3px;
    font-size: 18px;
  }
`;

const StyledBox = styled(Box)`
  margin-top: 20px;
  width: 100%;
`;
const StyledBoxAccount = styled(Box)`
  &.MuiBox-root {
    border: 1px solid #e2e8f0;
    border-radius: 5px;
    height: 71px;
    width: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 15px;
    & > .MuiBox-root:nth-child(1) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 20px;
      & h3 {
        font-size: 14px;
        color: #475569;
      }
      & .MuiSvgIcon-root {
        color: #475569;
        margin: 0;
        cursor: pointer;
      }
    }
    & > .MuiBox-root:nth-child(2) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 20px;
      & h3 {
        font-size: 12px;
        font-weight: normal;
        color: #475569;
      }
      & > .MuiBox-root {
        padding: 3px 10px;
        font-size: 10px;
        margin-left: 20px;
        color: #475569;
        width: 76px;
        height: 20px;
        background: rgba(71, 85, 105, 0.2);
        border-radius: 17px;
      }
    }
  }
`;

const StyledLoader = styled.div`
  width: auto;
`;
