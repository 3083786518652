import React, { FC } from 'react';
import styled from 'styled-components';
import emptyImg from '../../../assets/images/dashboard_slices_empty.svg';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../UI/Loader';

interface Props {
  isLoading: boolean;
}

export const Empty: FC<Props> = ({ isLoading }: Props) => {
  const navigate = useNavigate();
  return (
    <>
      {isLoading ? (
        <Loader color="#94a3b8" width="50px" height="50px" />
      ) : (
        <>
          <img src={emptyImg} alt="emptyImg" />
          <div>You don’t have any slices</div>
          <PointerDiv onClick={() => navigate('/slices')}>
            Add New Slice{' '}
            <AddIcon sx={{ marginLeft: '10px', fontSize: '32px' }} />
          </PointerDiv>
        </>
      )}
    </>
  );
};
const PointerDiv = styled.div`
  cursor: pointer;
`;
