import React, { FC, useState } from 'react';
import { Paper } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';

interface Props {
  errors: any;
  customer: any;
  disableForm: boolean;
  hideList: string[];
  updateCustomer: (data: any) => void;
  updateCustomerAddress: (data: any) => void;
  updateCustomerPassport: (data: any) => void;
  title: string;
  removeController?: () => void;
}

export const Controller: FC<Props> = ({
  errors,
  customer,
  disableForm,
  hideList,
  updateCustomer,
  updateCustomerAddress,
  updateCustomerPassport,
  title,
  removeController
}) => {
  const [customerControllerType, setCustomerControllerType] = useState('ssn');

  return (
    <Paper sx={{ padding: '25px 5px', position: 'relative' }} variant='outlined'>
      {removeController && (
        <IconButton sx={{ position: 'absolute', right: '0', top: '0' }} onClick={removeController}>
          <CloseIcon />
        </IconButton>
      )}
      <h5 style={{ margin: '0 15px 10px' }}>{title}</h5>

      <div>
        <TextField
          disabled={disableForm}
          error={errors['first_name'] && true}
          helperText={errors['first_name']}
          size='small'
          value={customer.first_name || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateCustomer({ first_name: e.target.value })
          }
          label='First Name'
          type='text'
          required
        />
        <TextField
          disabled={disableForm}
          error={errors['last_name'] && true}
          helperText={errors['last_name']}
          size='small'
          value={customer.last_name || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateCustomer({ last_name: e.target.value })
          }
          label='Last Name'
          type='text'
          required
        />
      </div>
      <div>
        {hideList.includes('title') === false && (
          <TextField
            disabled={disableForm}
            error={errors['title'] && true}
            helperText={errors['title']}
            size='small'
            value={customer.title || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateCustomer({ title: e.target.value })
            }
            label='Title'
            type='text'
            required
          />
        )}
        {hideList.includes('date_of_birth') === false && (
          <TextField
            disabled={disableForm}
            error={errors['date_of_birth'] && true}
            helperText={errors['date_of_birth']}
            size='small'
            value={customer.date_of_birth || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateCustomer({ date_of_birth: e.target.value })
            }
            label='Date Of Birth'
            placeholder='YYYY-MM-DD'
            type='text'
            required
          />
        )}
      </div>

      {hideList.includes('passport') === false && (
        <Paper sx={{ p: 1 }} variant='outlined'>
          <RadioGroup
            sx={{ padding: '0 10px' }}
            row
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const value = (event.target as HTMLInputElement).value;
              if (value === 'ssn') {
                updateCustomerPassport(null);
              } else if (value === 'passport') {
                updateCustomer({ ssn: '' });
              }
              setCustomerControllerType((event.target as HTMLInputElement).value);
            }}
            name='row-radio-buttons-group'>
            <FormControlLabel
              checked={customerControllerType === 'ssn'}
              value='ssn'
              control={<Radio />}
              label='SSN'
            />
            <FormControlLabel
              checked={customerControllerType === 'passport'}
              value='passport'
              control={<Radio />}
              label='Passport'
            />
          </RadioGroup>
          {customerControllerType === 'ssn' ? (
            <TextField
              disabled={disableForm}
              error={errors['ssn'] && true}
              helperText={errors['ssn']}
              size='small'
              value={customer.ssn}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateCustomer({ ssn: e.target.value })
              }
              label='SSN'
              type='text'
              required
            />
          ) : (
            <>
              <TextField
                disabled={disableForm}
                error={errors['passport.number'] && true}
                helperText={errors['passport.number']}
                placeholder='For a non-U.S. person'
                size='small'
                value={customer.passport?.number}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateCustomerPassport({ number: e.target.value })
                }
                label='Passport Number'
                type='text'
                required
              />
              <TextField
                disabled={disableForm}
                error={(errors['passport.country'] || errors['identification.country']) && true}
                helperText={errors['passport.country'] || errors['identification.country']}
                placeholder='Two digit ISO code, e.g. US.'
                size='small'
                value={customer.passport?.country}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateCustomerPassport({ country: e.target.value })
                }
                label='Country'
                type='text'
                required
              />
            </>
          )}
        </Paper>
      )}
      <br />
      <h5 style={{ margin: '0px 15px 10px' }}>{title} Address</h5>
      <TextField
        disabled={disableForm}
        error={errors['address.address1'] && true}
        helperText={errors['address.address1']}
        size='small'
        value={customer.address?.address1 || ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          updateCustomerAddress({ address1: e.target.value })
        }
        label='Address 1'
        type='text'
        required
      />
      <TextField
        disabled={disableForm}
        error={errors['address.address2'] && true}
        helperText={errors['address.address2']}
        size='small'
        value={customer.address?.address2 || ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          updateCustomerAddress({ address2: e.target.value })
        }
        label='Address 2'
        type='text'
      />

      <div>
        <TextField
          disabled={disableForm}
          error={errors['address.city'] && true}
          helperText={errors['address.city']}
          size='small'
          value={customer.address?.city || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateCustomerAddress({ city: e.target.value })
          }
          label='City'
          type='text'
          required
        />
        <TextField
          disabled={disableForm}
          error={errors['address.state_province_region'] && true}
          helperText={errors['address.state_province_region']}
          placeholder='Two-letter US state'
          size='small'
          value={customer.address?.state_province_region || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateCustomerAddress({ state_province_region: e.target.value })
          }
          label='State (Province, Region)'
          type='text'
          required
        />
      </div>
      <div>
        <TextField
          disabled={disableForm}
          error={errors['address.postal_code'] && true}
          helperText={errors['address.postal_code']}
          size='small'
          value={customer.address?.postal_code || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateCustomerAddress({ postal_code: e.target.value })
          }
          label='Postal Code'
          type='text'
        />
        <TextField
          disabled={disableForm}
          error={errors['address.country'] && true}
          helperText={errors['address.country']}
          placeholder='Two digit ISO code, e.g. US.'
          size='small'
          value={customer.address?.country || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateCustomerAddress({ country: e.target.value })
          }
          label='Country'
          type='text'
          required
        />
      </div>
    </Paper>
  );
};
