import React, {FC} from 'react';
import styled from 'styled-components';

export interface ComponentProps {
  width?: string;
  height?: string;
  margin?: string;
  color?: string;
};

export const Loader:FC <ComponentProps> = ({height, width = '100px', margin = '0', color = '#353A3F'}) => {
  return (
    <StyledLoader width={width} height={height ?? width} margin={margin} color={color}>
      <div className="loader"></div>
    </StyledLoader>
  );
};

const StyledLoader = styled.div<ComponentProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${props => props.margin};

  .loader {
    width: ${props => props.width};
    height: ${props => props.height};
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: transparent;
    border-right-color: ${props => props.color};
    border-bottom-color: transparent;
    border-left-color: ${props => props.color};
    animation: rotate-loading 1.5s linear 0s infinite normal;
    transform-origin: 50% 50%;
  }

  @keyframes rotate-loading {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
  }
`;

