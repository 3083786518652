import { Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import Formatter from '../../../helpers/format';
import { useActions } from '../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { ITransferHistory } from '../../../models/ITransfer';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { IBankAccount } from '../../../models/IBankAccount';

interface Props {
  transfer: ITransferHistory;
}

export const TransferHistoryItem: FC<Props> = ({ transfer }: Props) => {
  const { accounts } = useTypedSelector(state => state.bankAccounts);
  const [withdrawal, setWithdrawal] = useState('-');
  const [deposit, setDeposit] = useState('-');

  function getAccount(link_index: number, account_index: number) {
    return accounts.find(
      (item: IBankAccount) => item.accountIndex === account_index && item.linkIndex === link_index
    );
  }

  useEffect(() => {
    if (transfer.source_link_index !== null && transfer.source_account_index !== null) {
      const account = getAccount(transfer.source_link_index, transfer.source_account_index);

      if (account) {
        setWithdrawal(Formatter.bankName(account, 7, true));
      } else {
        setWithdrawal('-');
      }
    }
    if (transfer.destination_account_index !== null && transfer.destination_link_index !== null) {
      const account = getAccount(
        transfer.destination_link_index,
        transfer.destination_account_index
      );

      if (account) {
        setDeposit(Formatter.bankName(account, 7, true));
      } else {
        setDeposit('-');
      }
    }
  }, [transfer]);

  return (
    <TableRow
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        background: '#F8FAFC',
        '& .MuiTableCell-root': {
          height: '36px',
          fontWeight: 400,
          fontSize: '12px',
          color: '#475569',
          borderBottom: '2px solid #fff'
        }
      }}>
      <TableCell component='th' scope='row'>
        {Formatter.date(new Date(transfer.date), 'locale')}
      </TableCell>
      <TableCell>{withdrawal}</TableCell>
      <TableCell>{deposit}</TableCell>
      <TableCell>{Formatter.money(transfer.amount || 0)}</TableCell>
      <TableCell
        className={transfer.transaction_status ? transfer.transaction_status : ''}
        sx={{
          padding: '5px 10px',
          '& div': {
            height: '30px',
            borderRadius: '20px',
            fontWeight: 400,
            fontSize: '12px',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'capitalize'
          },
          '&.success div': {
            background: '#D9FFD2',
            color: '#268D13'
          },
          '&.pending div': {
            background: '#FFED8F',
            color: '#9E8606'
          },
          '&.failed div': {
            background: '#FFD6D6',
            color: '#F31919'
          }
        }}>
        <div>{transfer.transaction_status}</div>
      </TableCell>
    </TableRow>
  );
};
