import { FC, useState } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Service from '../../../API/service';
import {HeaderLogo} from '../../UI/HeaderLogo'
import headerBackImg from '../../../assets/images/landing/header-back.png';
import stripeImg from '../../../assets/images/landing/stripe.png';
import notebookImg from '../../../assets/images/landing/notebook.png';
import {AuthModal} from "../AuthModal";

interface Props {}

export const Header: FC<Props> = ({}) => {
  const [auth, setAuth] = useState(false);
  return (
    <StyledModule>
      <StyledMenu>
        <HeaderLogo/>
        <StyledButtons>
          <Button variant='light' onClick={() => Service.login()}>
            Login
          </Button>
          <Button sx={{ width: '95px' }} variant='dark' onClick={() => setAuth(true)}>
            Try it free
          </Button>
        </StyledButtons>
      </StyledMenu>
      <StyledHeaderContent>
        <StyledHeaderContentText>
          <h1>Put your profit in your Pocket</h1>
          <p>
            Hasselfree profit calculator to make your life easier. Drop your email for an early
            access.
          </p>
          <Button variant='dark' onClick={() => setAuth(true)}>
            Try it free
          </Button>
        </StyledHeaderContentText>
        <img src={notebookImg} alt='notebook' />
      </StyledHeaderContent>
      {auth && <AuthModal setVisible={setAuth} />}
    </StyledModule>
  );
};

const StyledModule = styled.div`
  width: 100%;
  background: url(${headerBackImg});
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100%;
`;
const StyledMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10%;
  height: 120px;
  @media (max-width: 900px) {
    width: 90%;
    padding: 0 5%;
    margin: auto;
  }
`;
const StyledButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 200px;
  & > .MuiButtonBase-root {
    margin: 10px;
    height: 38px;
  }
  @media (max-width: 900px) {
    & > .MuiButtonBase-root {
      margin: 5px;
    }
  }
`;
const StyledHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 706px;
  & > * {
    :nth-child(1) {
      width: 47%;
    }
    :nth-child(2) {
      width: 53%;
    }
  }
  @media (max-width: 900px) {
    & > * {
      :nth-child(1) {
        width: 80%;
      }
      :nth-child(2) {
        display: none;
      }
    }
  }
`;
const StyledHeaderContentText = styled.div`
  margin-left: 9%;
  height: 340px;
  margin-bottom: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  & > h1 {
    background: url(${stripeImg});
    background-repeat: no-repeat;
    font-weight: 600;
    background-position: -3px 35px;
    font-size: 48px;
    color: #1b3191;
    margin-left: -20px;
    padding-left: 20px;
    margin-top: 20px;
    line-height: 63px;
  }
  & > p {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #5c79e9;
    margin-top: 0;
    margin-bottom: 45px;
  }
  & > .MuiButtonBase-root {
    font-size: 18px;
    width: 128px;
    height: 56px;
  }
  @media (max-width: 900px) {
    margin: auto;
  }
`;
