import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { StyledItem } from '../components/parts/StyledItem';
import PageFrame from '../components/parts/PageFrame';
import Grid from '@mui/material/Grid';
import { DashboardIncome } from '../components/parts/DashboardIncome';
import { DashboardMain } from '../components/parts/DashboardMain';
import { DashboardPie } from '../components/parts/DashboardPie';
import { QuickTransfer } from '../components/parts/QuickTransfer';
import { useTypedSelector } from '../hooks/useTypedSelector';
import { Alert } from '@mui/material';
import Formatter from '../helpers/format';
import Service from '../API/service';

export const Dashboard: FC<{}> = () => {
  const [dwollaBalance, setDwollaBalance] = useState<number>(0);

  useEffect(() => {
    Service.getDwollaBalance().then(({ data }) => {
      setDwollaBalance(Number(data.balance));
    });
  }, []);

  return (
    <PageFrame>
      <HeaderContainer>
        <StyledH>Dashboard</StyledH>
      </HeaderContainer>
      <Grid container spacing={{ xl: 3, xs: 2 }}>
        <Grid item xs={12} md={12} lg={8}>
          <StyledItem>
            <DashboardIncome />
          </StyledItem>
          <StyledItem>
            <DashboardMain />
          </StyledItem>
        </Grid>
        <StyledGrid item xs={12} md={12} lg={4}>
          <StyledItem>
            <StyledAlert severity='info' icon={false}>
              Transfer Balance {Formatter.money(dwollaBalance)}
            </StyledAlert>
          </StyledItem>
          <StyledItem>
            <DashboardPie />
          </StyledItem>
          <StyledItem>
            <QuickTransfer />
          </StyledItem>
        </StyledGrid>
      </Grid>
    </PageFrame>
  );
};

const StyledH = styled.h2`
  margin: 0 0 15px;
  flex-grow: 1;
`;

const StyledAlert = styled(Alert)`
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const HeaderContainer = styled.div`
  margin: 0 0 15px;
  display: flex;
  align-items: end;
`;

const StyledGrid = styled(Grid)`
  @media (max-width: 1199px) {
    &.MuiGrid-root {
      padding-top: 0 !important;
    }
  }
`;
