import axios, { AxiosError } from 'axios';
import Formatter from '../helpers/format';
import { useActions } from '../hooks/useActions';
import { IDwollaBeneficialOwner, IDwollaCustomer } from '../models/IDwollaCustomer';
import { ISlice } from '../models/ISlice';
import { IUser } from '../models/IUser';
import { IBankAccountDeleteData } from '../models/IBankAccount';

let baseURL: string;

if (process.env.NODE_ENV === 'production') {
  baseURL = `https://api.${window.location.host}/api/v1`;
} else {
  baseURL = `https://api.profitpie.tech.anroit.com/api/v1`;
}

const service = axios.create({
  baseURL: baseURL,
  withCredentials: true
});

export default class Service {
  static async register(data: IUser) {
    const response = await service.post(`/register`, data);
    return response;
  }

  static async login() {
    document.location.href = `${baseURL}/login`;
  }

  static async logout() {
    document.location.href = `${baseURL}/logout`;
  }

  static async accountInfo() {
    const response = await service.get(`/account/profile`);
    return response;
  }

  static async updatePassword(password: string, password_confirm: string) {
    return await service.post(`account/profile/update-password`, {
      password,
      password_confirm
    });
  }

  static async uploadPhoto(image: File) {
    return await service.post(
      `/account/profile/update`,
      { picture: image },
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  }

  static async getBusinessClassification() {
    return await service.get('/business-classifications');
  }

  static async getDwollaBalance() {
    return await service.get('/dwolla-balance');
  }

  static async dwollaCustomer() {
    return await service.get(`/get-customer-info`);
  }

  static async updateDwollaCustomer(data: IDwollaCustomer) {
    data.type = 'business';
    return await service.post(`/update-customer-info`, data);
  }

  static async addBeneficialOwner(data: IDwollaBeneficialOwner) {
    return await service.post(`/add-beneficial-owner`, data);
  }

  static async bankAccounts() {
    return await service.get(`/account/funding-sources`);
  }

  static async removeDefferentAccounts() {
    return await service.get('/compare-funding-sources');
  }

  static async setDefaultBankAccount(dwolla_url: string) {
    return await service.post(`/set-default-account`, { dwolla_url });
  }

  static async deleteBankAccount(data: IBankAccountDeleteData) {
    return await service.post('/delete-bank-account', data);
  }

  static async createLink() {
    return await service.post(`/create-link`);
  }

  static async addAccount(public_token: string) {
    return await service.post(`/add-funding-source`, { public_token });
  }

  static async slices() {
    const { data } = await service.get('/slice');
    return data;
  }

  static async createSlice(slice: ISlice) {
    return await service.post(`/slice/create`, slice);
  }

  static async updateSlice(slice: ISlice) {
    return await service.post(`/slice/update/${slice.id}`, slice);
  }

  static async deleteSlice(slice: ISlice) {
    return await service.delete(`/slice/delete/${slice.id}`);
  }

  static async sliceCreate(data: ISlice) {
    const slice = await service.post('/slice/create', data);
    return slice;
  }

  static async getTransfersAmount(start: Date, end: Date) {
    return await service.get(`/dashboard/transfers`, {
      params: {
        startDate: Formatter.date(start),
        endDate: Formatter.date(end)
      }
    });
  }

  static async transfers(active = true, page: number = 1) {
    const url = active ? `/slice/transfers` : `/slice/transfers-history`;
    return await service.get(url, { params: { page } });
  }

  static async deleteTransfer(id: number) {
    return await service.delete(`/slice/transfers/delete/${id}`);
  }

  static async sendContact(data: { message: string; subject: string; captcha: boolean }) {
    const response = await service.post(`/contact/mail`, data);
    return response;
  }

  static async sendFeature(data: { message: string; captcha: boolean }) {
    const response = await service.post(`/contact/feature`, data);
    return response;
  }

  static async quickTransfer(data: {
    source_link_index: number;
    source_account_index: number;
    destination_link_index: number | null;
    destination_account_index: number | null;
    amount: number;
  }) {
    const response = await service.post(`/transfer`, data);
    return response;
  }

  static async transfer(id: number, amountDue: number) {
    const response = await service.post(`/slice/transfer/${id}`, { amountDue });
    return response;
  }

  static async subscriptions() {
    return await service.get(`/account/subscriptions`);
  }

  static async subscribe(id: number) {
    return await service.post(`/account/buy-subscription`, { id });
  }

  static async unsubscribe() {
    return await service.post(`/account/delete-subscription`);
  }
}

export { baseURL };
