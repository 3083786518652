import { IBankAccount } from '../models/IBankAccount';

export default class Formatter {
  static money(num: number): string {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(num);
  }

  static date(date: Date = new Date(), format: string = 'iso'): string {
    if (format === 'locale') {
      return date.toLocaleDateString('en-US', { timeZone: 'UTC' });
    }
    if (format === 'long') {
      return new Intl.DateTimeFormat('en-US', {
        dateStyle: 'long',
        timeZone: 'UTC'
      }).format(new Date());
    }
    return date.toISOString().split('T')[0];
  }

  static bankName(
    account: IBankAccount,
    symbols: number | false = false,
    withLast: boolean = false
  ): string {
    const bankName = symbols ? account.bankName.substring(0, symbols) : account.bankName;
    const suffix = withLast && `***${account.last4}`;
    return `${bankName}${suffix}`;
  }
}
