import { Dispatch } from 'react';
import Service from '../../API/service';
import { IDwollaCustomer } from '../../models/IDwollaCustomer';
import {
  DwollaAction,
  DwollaEnum,
  SetDwollaCustomerAction,
  SetDwollaCustomerDisableListAction,
  SetDwollaControllerDisableAction,
  UpdateDwollaCustomerAction,
  UpdateDwollaCustomerControllerAction,
  UpdateDwollaCustomerControllerAddressAction,
  UpdateDwollaCustomerControllerPassportAction,
  AddingBeneficialOwnersAction,
  RemoveBeneficialOwnersAction,
  EditBeneficialOwnersAction,
  EditBeneficialOwnersAddressAction,
  EditBeneficialOwnersPassportAction,
  SetErrorAction,
  SetIsLoadingAction
} from '../reducers/dwolla/types';

export const setDwollaCustomer = (customer: IDwollaCustomer): SetDwollaCustomerAction => {
  return {
    type: DwollaEnum.SET_DWOLLA_CUSTOMER,
    payload: { ...customer, isVerified: customer.status === 'verified' }
  };
};

export const setDwollaCustomerDisableList = (
  customer: IDwollaCustomer
): SetDwollaCustomerDisableListAction => {
  return {
    type: DwollaEnum.SET_DWOLLA_CUSTOMER_DISABLE_LIST,
    payload: {
      first_name: customer.first_name ? true : false,
      last_name: customer.first_name ? true : false,
      date_of_birth: customer.date_of_birth ? true : false,
      business_name: customer.business_name ? true : false,
      business_type: customer.business_type ? true : false,
      business_classification: customer.business_classification ? true : false,
      ssn: customer.ssn || customer.ein ? true : false,
      ein: customer.ssn || customer.ein ? true : false
    }
  };
};

export const setDwollaControllerDisable = (disable: boolean): SetDwollaControllerDisableAction => {
  return {
    type: DwollaEnum.SET_DWOLLA_CONTROLLER_DISABLE,
    payload: disable
  };
};

export const updateDwollaCustomer = (payload: any): UpdateDwollaCustomerAction => {
  return {
    type: DwollaEnum.UPDATE_DWOLLA_CUSTOMER,
    payload: payload
  };
};

export const updateDwollaCustomerController = (
  payload: any
): UpdateDwollaCustomerControllerAction => {
  return {
    type: DwollaEnum.UPDATE_DWOLLA_CUSTOMER_CONTROLLER,
    payload: payload
  };
};

export const updateDwollaCustomerControllerAddress = (
  payload: any
): UpdateDwollaCustomerControllerAddressAction => {
  return {
    type: DwollaEnum.UPDATE_DWOLLA_CUSTOMER_CONTROLLER_ADDRESS,
    payload: payload
  };
};

export const updateDwollaCustomerControllerPassport = (
  payload: any
): UpdateDwollaCustomerControllerPassportAction => {
  return {
    type: DwollaEnum.UPDATE_DWOLLA_CUSTOMER_CONTROLLER_PASSPORT,
    payload: payload
  };
};

export const addingBeneficialOwners = (payload: any): AddingBeneficialOwnersAction => {
  return {
    type: DwollaEnum.ADDING_BENEFICIAL_OWNERS,
    payload: {
      first_name: '',
      last_name: '',
      date_of_birth: '',
      ssn: '',
      address: {
        address1: '',
        address2: '',
        city: '',
        state_province_region: '',
        postal_code: '',
        country: ''
      },
      passport: null
    }
  };
};

export const removeBeneficialOwners = (payload: any): RemoveBeneficialOwnersAction => {
  return {
    type: DwollaEnum.REMOVE_BENEFICIAL_OWNERS,
    payload: payload
  };
};

export const editBeneficialOwners = (payload: any): EditBeneficialOwnersAction => {
  return {
    type: DwollaEnum.EDIT_BENEFICIAL_OWNERS,
    payload: payload
  };
};

export const editBeneficialOwnersAddress = (payload: any): EditBeneficialOwnersAddressAction => {
  return {
    type: DwollaEnum.EDIT_BENEFICIAL_OWNERS_ADDRESS,
    payload: payload
  };
};

export const editBeneficialOwnersPassport = (payload: any): EditBeneficialOwnersPassportAction => {
  return {
    type: DwollaEnum.EDIT_BENEFICIAL_OWNERS_PASSPORT,
    payload: payload
  };
};

export const setIsLoading = (payload: boolean): SetIsLoadingAction => {
  return {
    type: DwollaEnum.SET_IS_LOADING,
    payload: payload
  };
};

export const setCustomerError = (payload: string): SetErrorAction => {
  return {
    type: DwollaEnum.SET_ERROR,
    payload: payload
  };
};

export const loadDwollaCustomer = () => {
  return async (dispatch: Dispatch<DwollaAction>) => {
    try {
      dispatch(setIsLoading(true));
      const response = await Service.dwollaCustomer();
      dispatch(setDwollaCustomer(response.data));
      dispatch(setDwollaCustomerDisableList(response.data));
      if (response.data.controller !== null) {
        dispatch(setDwollaControllerDisable(true));
        dispatch(updateDwollaCustomerController(response.data.controller));
        if (response.data.controller.address) {
          dispatch(updateDwollaCustomerControllerAddress(response.data.controller.address));
        }
        if (response.data.controller.passport) {
          dispatch(updateDwollaCustomerControllerPassport(response.data.controller.passport));
        }
      } else {
        dispatch(setDwollaControllerDisable(false));
      }
    } catch (e) {
      setCustomerError(
        'Something went wrong while loading customer account. Please try again later.'
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};
