import { Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import styled from 'styled-components';
import Service from '../../../API/service';
import Formatter from '../../../helpers/format';
import { useActions } from '../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { IBankAccount } from '../../../models/IBankAccount';
import { ITransfer } from '../../../models/ITransfer';
import { Loader } from '../../UI/Loader';

interface ColumnProps {
  header: string;
  value: string;
}
const Column: FC<ColumnProps> = ({ header, value }: ColumnProps) => {
  return (
    <div>
      <Typography color='#64748B' lineHeight={1.4} fontSize={12}>
        {header}
      </Typography>
      <Typography color='#1E293B' lineHeight={2} fontSize={14}>
        {value}
      </Typography>
    </div>
  );
};

interface Props {
  transfer: ITransfer;
  toAccount: IBankAccount | null;
  amountDue: number;
}

export const TransferItem: FC<Props> = ({ transfer, toAccount, amountDue }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isSkipLoading, setSkipIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { loadSlices } = useActions();
  const transaction = useTypedSelector(({ transaction }) => transaction);
  const { setIsTransactionLoaded, setTransaction } = useActions();
  const { accounts } = useTypedSelector(({ bankAccounts }) => bankAccounts);

  function sendTransfer() {
    setTransaction(amountDue ?? transfer.amount_due);
    setIsLoading(true);
    return Service.transfer(transfer.id, amountDue ?? transfer.amount_due)
      .then(data => {
        enqueueSnackbar('Your money was successfully transferred.', {
          variant: 'success'
        });
        setIsSuccess(true);
        loadSlices();
        return data;
      })
      .catch((e: any) => {
        if (e.response.status === 422) {
          enqueueSnackbar(e.response.data.message, {
            variant: 'error'
          });
          return Promise.reject(e);
        }
        enqueueSnackbar('Something went wrong. Please try again later.', {
          variant: 'error'
        });
        return Promise.reject(e);
      })
      .finally(() => {
        setIsLoading(false);
        setIsTransactionLoaded();
      });
  }

  function skipTransfer() {
    setSkipIsLoading(true);
    Service.deleteTransfer(transfer.id)
      .then(data => {
        enqueueSnackbar('Transfer skiped', {
          variant: 'success'
        });
        setIsSuccess(true);
        loadSlices();
        return data;
      })
      .catch((e: any) => {
        if (e.response.status === 422) {
          enqueueSnackbar(e.response.data.message, {
            variant: 'error'
          });
          return Promise.reject(e);
        }
        enqueueSnackbar('Something went wrong. Please try again later.', {
          variant: 'error'
        });
        return Promise.reject(e);
      })
      .finally(() => {
        setSkipIsLoading(false);
        setIsTransactionLoaded();
      });
  }

  return (
    <TransferContainer className={isSuccess ? 'hidden' : ''} color={transfer.color ?? '#eee'}>
      <Typography fontWeight='bold' fontSize={18} lineHeight={1.4} color={transfer.color ?? '#eee'}>
        {transfer.name}
      </Typography>
      <TransferBody>
        <Column
          header='Date Range'
          value={Formatter.date(new Date(transfer.date_range), 'locale')}
        />
        <Column header='Allocation' value={`${transfer.allocation}%`} />
        <Column
          header='Amount Due'
          value={Formatter.money(Number(((amountDue * transfer.allocation) / 100).toFixed(2)))}
        />
        <Column
          header='Account'
          value={toAccount ? Formatter.bankName(toAccount, 7, true) : 'not found'}
        />
        {!transfer.amount && (
          <StyledButtons>
            {accounts.find(
              (account: IBankAccount) => account.dwolla_url === transfer.to_funding_sources
            ) && (
              <Button
                onClick={sendTransfer}
                size='small'
                sx={{ minWidth: 104 }}
                className='active'
                disabled={isLoading}>
                {isLoading ? <Loader color='#fff' width='20px' height='20px' /> : 'Transfer Money'}
              </Button>
            )}
            <Button
              onClick={skipTransfer}
              size='small'
              sx={{ minWidth: 104 }}
              disabled={isSkipLoading}>
              {isSkipLoading ? <Loader color='#fff' width='20px' height='20px' /> : 'Skip'}
            </Button>
          </StyledButtons>
        )}
      </TransferBody>
    </TransferContainer>
  );
};
interface ContainerProps {
  color?: string;
}
const TransferContainer = styled.div<ContainerProps>`
  background-color: #f8fafc;
  background-image: url("data:image/svg+xml,%3Csvg width='7' height='9' viewBox='0 0 7 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='0.807692' cy='0.807692' r='0.807692' fill='%23D9D9D9'/%3E%3Ccircle cx='3.5' cy='0.807692' r='0.807692' fill='%23D9D9D9'/%3E%3Ccircle cx='6.19231' cy='0.807692' r='0.807692' fill='%23D9D9D9'/%3E%3Ccircle cx='0.807692' cy='4.03865' r='0.807692' fill='%23D9D9D9'/%3E%3Ccircle cx='3.5' cy='4.03865' r='0.807692' fill='%23D9D9D9'/%3E%3Ccircle cx='6.19231' cy='4.03865' r='0.807692' fill='%23D9D9D9'/%3E%3Ccircle cx='0.807692' cy='7.26912' r='0.807692' fill='%23D9D9D9'/%3E%3Ccircle cx='3.5' cy='7.26912' r='0.807692' fill='%23D9D9D9'/%3E%3Ccircle cx='6.19231' cy='7.26912' r='0.807692' fill='%23D9D9D9'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 6px 24px;
  border-left: 5px solid ${({ color }) => color ?? '#eee'};
  margin-bottom: 15px;
  padding: 15px;
  padding-left: 21px;
  border-radius: 5px;
  box-sizing: border-box;
  opacity: 1;
  height: auto;
  &.hidden {
    opacity: 0;
    height: 0px;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;
    background-color: green;
    transition: padding 0.5s linear, height 0.5s linear, margin 0.5s linear, opacity 0.5s linear;
  }
`;
const TransferBody = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StyledButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  margin-top: -20px;
  & > .MuiButtonBase-root {
    height: 30px;
    margin: 3px 0;
  }
`;
