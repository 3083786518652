import { Button, Grid } from '@mui/material';
import React, { FC, FormEvent, useRef, useState } from 'react';
import styled from 'styled-components';
import Service from '../../API/service';
import useInput from '../../hooks/useInput';
import { StyledAlert } from './StyledAlert';

interface Activable {
  active?: boolean;
}

export const PasswordUpdate: FC<{}> = () => {
  const password = useInput();
  const passwordConfirm = useInput();
  const [formErrors, setFormErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');

  function updatePassword(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    Service.updatePassword(password.value, passwordConfirm.value)
      .then(() => {
        setFormErrors([]);
        setSuccessMessage('Password was updated!');
        password.setValue('');
        passwordConfirm.setValue('');
      })
      .catch((e: any) => {
        if (e.response.data.password) {
          setFormErrors(e.response.data.password);
        } else {
          setFormErrors(['Unkown error.'] as never);
        }
      });
  }

  return (
    <Container>
      <form onSubmit={updatePassword}>
        <Grid container spacing={1.4} alignItems='end'>
          <Grid item xs={12} md={12} lg={6}>
            <LabelContainer>
              <Label htmlFor='input-password'>Current Password</Label>
              <Link href='#' onClick={e => e.preventDefault()}>
                Reset Password
              </Link>
            </LabelContainer>
            <Input
              active={true}
              id='input-password'
              type='password'
              {...password}
              name='password'
              required
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <Label htmlFor='input-password_confirm'>Re-Enter Password</Label>
            <Input
              id='input-password_confirm'
              type='password'
              {...passwordConfirm}
              name='password_confirm'
              required
            />
          </Grid>
        </Grid>
        {formErrors.map((error: string, n: number) => (
          <StyledAlert key={n} severity='error' icon={false}>
            {error}
          </StyledAlert>
        ))}
        {successMessage && <StyledAlert severity='success'>{successMessage}</StyledAlert>}
        <Notice>** We suggest to put a strong password for your account safety</Notice>
        <SaveButton type='submit' className='active'>
          Save Changes
        </SaveButton>
      </form>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 18px;
`;

const SaveButton = styled(Button)`
  &.MuiButton-root {
    padding: 8px 16px;
  }
`;

const Notice = styled.p`
  color: 000;
  font-size: 10px;
`;

const Label = styled.label`
  font-size: 12px;
  line-height: 140%;
`;

const Input = styled.input<Activable>`
  box-sizing: border-box;
  border: 1px solid #cbd5e1;
  border-radius: 4px;
  font-size: 10px;
  line-height: 140%;
  color: #475569;
  padding: 10px 12px;
  width: 100%;
  ${({ active }) => !active && 'background-color: #cbd5e1'}
`;

const Link = styled.a`
  font-size: 10px;
  line-height: 140%;
`;

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
