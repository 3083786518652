import React, { FC, useEffect, useState } from 'react';
import { Alert, Box } from '@mui/material';
import styled from 'styled-components';
import { SubscriptionCard } from './SubscriptionCard';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import Service from '../../../API/service';
import useRequest from '../../../hooks/useRequest';
import { Loader } from '../../UI/Loader';
import { ISubscription } from '../../../models/ISubscription';
import { IncomeAccountSelector } from '../IncomeAccountSelector';

export const SubscriptionPlan: FC<{}> = () => {
  const [subscriptions, isSubsLoading, error, loadSubscriptions] = useRequest(
    Service.subscriptions
  );
  const { user } = useTypedSelector(({ auth }) => auth);

  useEffect(() => {
    loadSubscriptions();
  }, []);

  const isTrial = !user.subscription?.id && !!user.trial;

  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <Title>Subscription plan</Title>
      {isTrial && (
        <Notice>
          You are using a trial version.{' '}
          <strong>Your trial period ends in {user.trial} days</strong>
        </Notice>
      )}
      {error && <Alert severity="error">Subscriptions loading failed</Alert>}
      {isSubsLoading && <Loader color="#94a3b8" />}
      <SubscriptionList>
        {((subscriptions ?? []) as ISubscription[]).map((subscription) => (
          <SubscriptionCard
            key={subscription.id}
            active={user.subscription?.id === subscription.id}
            subscription={subscription}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        ))}
      </SubscriptionList>
      <Notice>
        **Your monthly subscription will be deducted from your Income Account.
      </Notice>
      <IncomeAccountCard>
        <>
          <IncomeTitle>Income Account</IncomeTitle>
          <BodyBox>
            <IncomeAccountSelector />
          </BodyBox>
        </>
      </IncomeAccountCard>
    </>
  );
};

const IncomeTitle = styled.div`
  background-color: '#1c3fc8';
  color: '#fff';
  padding: 5px 10px;
  border-radius: 5px;
`;

const Title = styled.h3`
  font-size: 18px;
`;

const Notice = styled.p`
  font-size: 14px;
`;

const SubscriptionList = styled.div`
  display: flex;
  flex-direction: column;
`;

const IncomeAccountCard = styled.div`
  margin: 8px 0;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 415px;
  box-sizing: border-box;
  background-color: #1c3fc8;
  color: #fff;
`;
const BodyBox = styled(Box)`
  & .MuiSelect-select {
    background: #fff;
    color: #64748b;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  & .MuiBox-root {
    font-size: 10px;
    line-height: 12px;
    font-weight: normal;
    color: #fbeb62;
    margin-top: 6px;
  }
`;
