import { Dispatch } from 'react';
import Service from '../../API/service';
import { ISlice } from '../../models/ISlice';
import {
  SetErrorAction,
  SetIsLoadingAction,
  SetSlicesAction,
  SlicesAction,
  SlicesActionsEnum,
} from '../reducers/slices/types';

export const setSlices = (slices: ISlice[]): SetSlicesAction => {
  return {
    type: SlicesActionsEnum.SET_SLICES,
    payload: slices,
  };
};

export const setIsSlicesLoading = (payload: boolean): SetIsLoadingAction => {
  return {
    type: SlicesActionsEnum.SET_IS_LOADING,
    payload,
  };
};

export const setSlicesError = (payload: string): SetErrorAction => {
  return {
    type: SlicesActionsEnum.SET_ERROR,
    payload,
  };
};

export const loadSlices = () => {
  return async (dispatch: Dispatch<SlicesAction>) => {
    try {
      dispatch(setIsSlicesLoading(true));
      const response: any = await Service.slices();
      dispatch(setSlices(response));
    } catch (e) {
      dispatch(setSlicesError('Error while loading slices. Please try again.'));
    } finally {
      dispatch(setIsSlicesLoading(false));
    }
  };
};
