import React, { FC, useState } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { Loader } from '../UI/Loader';
import Service from '../../API/service';

interface Props {
  setVisible: (value: boolean) => void;
  onConfirm: () => void;
}

export const ConfirmModal: FC<Props> = ({ setVisible, onConfirm }) => {
  return (
    <StyledModal
      open={true}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <StyledBox>
        <Styledh3>Transfer confirmation</Styledh3>
        <ButtonBox>
          <Button onClick={onConfirm} className='active'>
            Confirm
          </Button>
          <Button onClick={() => setVisible(false)}>Cancel</Button>
        </ButtonBox>
      </StyledBox>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  & .MuiBackdrop-root {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: 0px 12px 33.4221px rgba(21, 49, 156, 0.1);
  max-width: 400px;
  padding: 5px 35px;
  border-radius: 8px;
  width: 90%;
  margin: 0 auto;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  > * {
    width: 100%;
  }
  & .MuiButtonBase-root.MuiButton-root {
    height: 38px;
    width: 115px;
  }
`;

const Styledh3 = styled.h3`
  text-align: center;
`;

const ButtonBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 20px;
`;
