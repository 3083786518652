import { FC } from 'react';
import styled from 'styled-components';
import linkIconImg from '../../../assets/images/landing/link-icon.png';
import pieIconImg from '../../../assets/images/landing/pie-icon.png';
import dollarIconImg from '../../../assets/images/landing/dollar-icon.png';
import videoHeaderImg from '../../../assets/images/landing/video-header.png';

interface Props {}

export const Video: FC<Props> = ({}) => {
  return (
    <StyledModule>
      <StyledVideoText>
        <StyledVideoTextH>How profit pie works</StyledVideoTextH>
        <StyledVideoTextP>
          <StyledVideoTextItem>
            <StyledVideoTextItemH>
              <img src={linkIconImg} alt='link-icon' />
              Link Income account
            </StyledVideoTextItemH>
            <StyledVideoTextItemP>
              In quis massa quis turpis elementum ultrices et et augue. Suspendisse potenti.
              Phasellus eu est eget quam tristique eleifend.
            </StyledVideoTextItemP>
          </StyledVideoTextItem>
          <StyledVideoTextItem>
            <StyledVideoTextItemH>
              <img src={pieIconImg} alt='pie-icon' />
              Create Slice
            </StyledVideoTextItemH>
            <StyledVideoTextItemP>
              In quis massa quis turpis elementum ultrices et et augue. Suspendisse potenti.
              Phasellus eu est eget quam tristique eleifend. Pellentesque semper quis eros consequat
              efficitur.
            </StyledVideoTextItemP>
          </StyledVideoTextItem>
          <StyledVideoTextItem>
            <StyledVideoTextItemH>
              <img src={dollarIconImg} alt='dollar-icon' />
              Allocate Money
            </StyledVideoTextItemH>
            <StyledVideoTextItemP>
              In quis massa quis turpis elementum ultrices et et augue. Suspendisse potenti.
              Phasellus eu est eget quam tristique eleifend. Pellentesque semper quis eros consequat
              efficitur.
            </StyledVideoTextItemP>
          </StyledVideoTextItem>
        </StyledVideoTextP>
      </StyledVideoText>
      <StyledVideoYoutube>
        <iframe
          src='https://www.youtube.com/embed/uXnLyUZYkVw'
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'></iframe>
      </StyledVideoYoutube>
    </StyledModule>
  );
};

const StyledModule = styled.div`
  background: #1b3190;
  min-height: 814px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  & > * {
    :nth-child(1) {
      width: 400px;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      margin-left: 49px;
      height: 550px;
    }
    :nth-child(2) {
    }
  }
  @media (max-width: 1000px) {
    flex-direction: column;
    padding: 0 5%;
    & > * {
      :nth-child(1) {
        width: 100%;
        margin: 50px auto 10px;
        height: auto;
      }
      :nth-child(2) {
        background: none;
        padding: 0;
        margin: 0 auto 50px;
        width: 90%;
      }
    }
  }
`;

const StyledVideoText = styled.div``;
const StyledVideoTextH = styled.div`
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  color: #ffffff;
  margin-bottom: 80px;
  @media (max-width: 1000px) {
    margin-bottom: 30px;
  }
`;
const StyledVideoTextP = styled.div``;
const StyledVideoTextItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
`;
const StyledVideoTextItemH = styled.div`
  font-weight: 500;
  font-size: 24px;
  color: #adbdff;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;
  & > img {
    margin-right: 10px;
  }
`;
const StyledVideoTextItemP = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #ffffff;
`;
const StyledVideoYoutube = styled.div`
  background: url(${videoHeaderImg});
  background-repeat: no-repeat;
  background-position: 43px 47px;
  background-size: 807px;
  margin-top: 16px;
  padding-top: 156px;
  margin-left: -40px;
  padding-left: 88px;
  padding-right: 50px;
  & > iframe {
    width: 704px;
    height: 432px;
    border: 6px solid #7690ff;
    border-radius: 18px;
  }
  @media (max-width: 1200px) {
    & > iframe {
      width: 500px;
      height: 300px;
    }
  }
  @media (max-width: 1000px) {
    margin-top: 0;
    & > iframe {
      width: calc(100% - 12px);
    }
  }
`;
