import React, { FC, useState } from 'react';
import { Paper, Button } from '@mui/material';
import styled from 'styled-components';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { useActions } from '../../../hooks/useActions';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import Add from '@mui/icons-material/Add';
import { Controller } from './Controller';

interface Props {
  errors: any;
  customer: any;
}

export const Beneficial: FC<Props> = ({ errors, customer }) => {
  const {
    addingBeneficialOwners,
    removeBeneficialOwners,
    editBeneficialOwners,
    editBeneficialOwnersAddress,
    editBeneficialOwnersPassport
  } = useActions();
  const { beneficials } = useTypedSelector(state => state.dwolla);

  return (
    <Paper sx={{ p: 1 }} variant='outlined'>
      <StyledButton className='active' startIcon={<Add />} onClick={addingBeneficialOwners}>
        Adding Beneficial Owner
      </StyledButton>
      {beneficials.map((item: any, i: number) => (
        <Controller
          disableForm={false}
          hideList={['title']}
          key={`beneficial${i}`}
          errors={errors[i] || {}}
          customer={beneficials[i] || {}}
          updateCustomer={(data: any) =>
            editBeneficialOwners({
              item: i,
              data: data
            })
          }
          updateCustomerAddress={(data: any) =>
            editBeneficialOwnersAddress({
              item: i,
              data: data
            })
          }
          updateCustomerPassport={(data: any) =>
            editBeneficialOwnersPassport({
              item: i,
              data: data
            })
          }
          title='Beneficial Owner'
          removeController={() => removeBeneficialOwners(i)}
        />
      ))}
    </Paper>
  );
};

const StyledButton = styled(Button)`
  &.MuiButtonBase-root {
    display: flex;
    margin: 20px auto;
  }
`;
