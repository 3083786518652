import { FC, useState } from 'react';
import styled from 'styled-components';
import { Header } from '../components/parts/Landing/Header';
import { Video } from '../components/parts/Landing/Video';
import { Info } from '../components/parts/Landing/Info';
import { Protect } from '../components/parts/Landing/Protect';
import { Review } from '../components/parts/Landing/Review';
import { Footer } from '../components/parts/Landing/Footer';

export const Landing: FC<{}> = () => {
  return (
    <StyledModule>
      <Header />
      <Video />
      <Info />
      <Protect />
      <Review />
      <Footer />
    </StyledModule>
  );
};
const StyledModule = styled.div``;
