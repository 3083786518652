import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import Service from '../API/service';
import { useActions } from './useActions';

export const useAddAccount = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [token, setToken] = useState(null);
  const { loadBankAccounts } = useActions();

  useEffect(() => {
    Service.createLink().then(({ data }) => {
      setToken(data.link_token);
    });
  }, []);

  const { open, ready } = usePlaidLink({
    token: token,
    onSuccess: (public_token, metadata) => {
      Service.addAccount(public_token)
        .then(loadBankAccounts)
        .catch(e => {
          Service.removeDefferentAccounts();
          enqueueSnackbar(
            'Error while adding a bank account. Please make sure that your select the correct account.',
            { variant: 'error' }
          );
        });
    }
  });
  return { open, ready };
};
