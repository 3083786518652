import React, { FC, useState } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { ButtonGroup, Select, MenuItem, InputAdornment } from '@mui/material';
import { Loader } from '../../UI/Loader';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PercentIcon from '@mui/icons-material/Percent';
import { GithubPicker } from 'react-color';
import { Weekly } from './Weekly';
import { Monthly } from './Monthly';
import { ISlice, TimetableTypeEnum } from '../../../models/ISlice';
import { useTypedSelector } from '../../../hooks/useTypedSelector';

interface Props {
  title: string;
  cancel: () => void;
  setSlice: (value: any) => any;
  slice: ISlice;
  save: () => Promise<void>;
  deleteSlice: (slice: ISlice) => void;
  errorMessage: string;
}

interface ButtonBox {
  bg: string;
}

export const SlicesRemidnerModal: FC<Props> = ({
  title,
  slice,
  setSlice,
  cancel,
  save,
  deleteSlice,
  errorMessage
}) => {
  const { name, allocation, color, to_funding_sources, timetable_type, timetable_value } = slice;
  const { accounts } = useTypedSelector(state => state.bankAccounts);
  const [isLoading, setIsLoading] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const typeList = [
    { label: 'Daily', value: TimetableTypeEnum.DAILY },
    { label: 'Weekly', value: TimetableTypeEnum.WEEKLY },
    { label: 'Monthly', value: TimetableTypeEnum.MONTHLY }
  ];
  const { slices } = useTypedSelector(({ slices }) => slices);
  const maxValue =
    100 -
    Number(
      slices
        .filter(item => item.id !== slice.id)
        .reduce((accumulator, item) => item.allocation + accumulator, 0)
        .toFixed(2)
    );

  function handleChangeColor(newColor: any) {
    setSlice({ ...slice, color: newColor.hex });
  }

  function handleChange(e: any) {
    const { value, name } = e.target;
    setSlice({ ...slice, [name]: value });
  }

  function handleChangeType(type: string) {
    setSlice({ ...slice, timetable_type: type });
  }

  function saveChanges() {
    setIsLoading(true);
    save().finally(() => setIsLoading(false));
  }

  function handleChangeTimetableValue(date: string | null) {
    setSlice({ ...slice, timetable_value: date });
  }

  return (
    <StyledModal
      open={true}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <StyledBox>
        <Styledh3>
          <>{title}</>
        </Styledh3>

        <Styledh5>Slice name</Styledh5>
        <StyledTextField
          autoComplete='off'
          name='name'
          onChange={handleChange}
          value={name}
          size='small'
        />

        <StyledFieldBox>
          <Box>
            <Styledh5>Allocation (max: {maxValue})</Styledh5>
            <StyledTextField
              fullWidth
              size='small'
              name='allocation'
              value={allocation}
              onChange={e => {
                let value: any = e.target.value;

                if (value) {
                  value = Number(value);
                  if (value > maxValue) value = maxValue;
                  if (value < 0) value = 0;
                }

                setSlice({ ...slice, allocation: value });
              }}
              InputProps={{
                inputProps: {
                  type: 'number',
                  min: 0,
                  max: maxValue
                },
                endAdornment: (
                  <InputAdornment position='end'>
                    <PercentIcon sx={{ fontSize: '15px' }} />
                  </InputAdornment>
                )
              }}
            />
          </Box>
          <Box>
            <Styledh5>Linked Bank Account</Styledh5>
            <Select
              size='small'
              name='to_funding_sources'
              fullWidth
              value={to_funding_sources}
              onChange={handleChange}
              required>
              {accounts.map(account => (
                <MenuItem key={account.dwolla_url} value={account.dwolla_url}>
                  {account.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </StyledFieldBox>

        <StyledColorBox>
          <Styledh5>Pick a color Identifier</Styledh5>
          <StyledColor>
            <StyledButtonColor onClick={() => setDisplayColorPicker(!displayColorPicker)}>
              <StyledButtonBox bg={color}></StyledButtonBox>
              <StyledButtonText>
                <KeyboardArrowDownIcon />
              </StyledButtonText>
            </StyledButtonColor>
            {displayColorPicker ? (
              <ColorPickerPopover>
                <ColorPickerCover onClick={() => setDisplayColorPicker(false)} />
                <GithubPicker
                  colors={[
                    '#F43F5E',
                    '#D946EF',
                    '#8B5CF6',
                    '#14B8A6',
                    '#0EA5E9',
                    '#EAB308',
                    '#F97316',
                    '#22C55E',
                    '#EC4899',
                    '#2563EB'
                  ]}
                  width='262px'
                  color={color}
                  onChange={handleChangeColor}
                />
              </ColorPickerPopover>
            ) : null}
          </StyledColor>
        </StyledColorBox>

        <StyledButtonGroup aria-label='outlined primary button group'>
          {typeList.map(({ label, value }) => (
            <Button
              key={label}
              className={value === timetable_type ? 'active' : ''}
              onClick={e => handleChangeType(value)}>
              {label}
            </Button>
          ))}
        </StyledButtonGroup>

        <StyledTypeBox>
          {timetable_type === TimetableTypeEnum.WEEKLY ? (
            <Weekly timetable_value={timetable_value} onClick={handleChangeTimetableValue} />
          ) : timetable_type === TimetableTypeEnum.MONTHLY ? (
            <Monthly timetable_value={timetable_value} onClick={handleChangeTimetableValue} />
          ) : (
            ''
          )}
        </StyledTypeBox>

        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <StyledFooterBox>
          <ButtonBox>
            <Button
              sx={{ width: '127px' }}
              onClick={saveChanges}
              disabled={isLoading ? true : false}
              className='active'>
              {isLoading ? (
                <Loader color='#fff' width='25px' height='25px' />
              ) : (
                <span>Save Changes</span>
              )}
            </Button>
            <Button sx={{ width: '80px' }} onClick={cancel}>
              Cancel
            </Button>
          </ButtonBox>
          {slice.id && (
            <StyledDeleteButton onClick={() => deleteSlice(slice)}>
              <ErrorOutlineIcon />
              Delete Category
            </StyledDeleteButton>
          )}
        </StyledFooterBox>
      </StyledBox>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  user-select: none;
  overflow: auto;
  & .MuiBackdrop-root {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const StyledBox = styled(Box)`
  outline: none;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 0);
  background: #fff;
  box-shadow: 0px 12px 33.4221px rgba(21, 49, 156, 0.1);
  max-width: 512px;
  padding: 5px 35px;
  border-radius: 8px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  > * {
    width: 100%;
  }
`;

const StyledFieldBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 14px;
  > * {
    width: 48%;
  }
`;

const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: 30px;
  & .MuiButtonBase-root.MuiButton-root {
    width: 80px;
    height: 27px;
    background: #eaedfc;
    color: #64748b;
    font-size: 12px;
    border: 0;
    &.active {
      background: #0f236f;
      color: #eaedfc;
      cursor: default;
    }
  }
`;

const Styledh3 = styled.h3`
  text-align: left;
  margin: 23px 0;
`;

const Styledh5 = styled.h5`
  text-align: left;
  margin: 2px 0;
  font-weight: normal;
`;

const StyledTextField = styled(TextField)``;

const StyledColorBox = styled(Box)`
  margin-top: 14px;
`;

const StyledColor = styled.div`
  display: flex;
  position: relative;
`;

const StyledButtonColor = styled.button`
  display: flex;
  align-items: center;
  width: 141px;
  height: 40px;
  padding: 0;
  border-radius: 6px;
  background: #fff;
  cursor: pointer;
  overflow: hidden;
`;

const StyledButtonBox = styled.span<ButtonBox>`
  width: 100px;
  height: 100%;
  background: ${props => props.bg};
`;

const StyledButtonText = styled.span`
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const ColorPickerCover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const ColorPickerPopover = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  top: 50px;
`;

const StyledTypeBox = styled(Box)`
  margin-top: 20px;
`;

const StyledFooterBox = styled(Box)`
  margin-top: 30px;
  margin-bottom: 23px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ButtonBox = styled(Box)`
  & .MuiButtonBase-root.MuiButton-root {
    height: 38px;
    margin-right: 10px;
  }
`;

const StyledDeleteButton = styled(Button)`
  &.MuiButtonBase-root.MuiButton-root {
    background-color: transparent;
    color: #ff6676;
    padding: 0;
    font-size: 12px;
  }
  & svg {
    font-size: 16px;
    margin-right: 5px;
  }
`;
