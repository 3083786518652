import React from 'react';
import { Landing } from '../pages/Landing';
import { Dashboard } from '../pages/Dashboard';
import { Slices } from '../pages/Slices';
import { Profile } from '../pages/Profile';

export interface IRoute {
  path: string;
  component: React.ComponentType;
}

export enum RouteNames {
  LANDING = '/',
  DASHBOARD = '/dashboard',
  SLICES = '/slices',
  PROFILE = '/profile'
}

export const publicRoutes: IRoute[] = [
  {
    path: RouteNames.LANDING,
    component: Landing
  }
];

export const privateRoutes: IRoute[] = [
  { path: RouteNames.DASHBOARD, component: Dashboard },
  { path: RouteNames.SLICES, component: Slices },
  { path: RouteNames.PROFILE, component: Profile }
];
