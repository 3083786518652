import React, { FC, useState } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { Loader } from '../UI/Loader';
import Service from '../../API/service';

interface Props {
  setVisible: (value: boolean) => void;
}

export const FeatureRequestModal: FC<Props> = ({ setVisible }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [fail, setFail] = useState<boolean>(false);

  function sendForm() {
    setIsLoading(true);
    if (message) {
      Service.sendFeature({
        message: message,
        captcha: true
      }).then((res) => {
        setIsLoading(false);

        if (res.status === 200) {
          setSuccess(true);
        } else {
          setFail(true);
        }

        setTimeout(() => {
          setVisible(false);
          setSuccess(false);
          setFail(false);
        }, 3000);
      });
    } else {
      setIsLoading(false);
    }
  }

  return (
    <StyledModal open={true} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <StyledBox>
        {
          success ? <StyledStatus>Feature request was sent successfully</StyledStatus>
          :
          fail ? <StyledStatus>Feature request was not sent</StyledStatus>
          :
            <>
              <Styledh3>Feature request</Styledh3>
              <Styledh5>What would you like to request?</Styledh5>
              <StyledTextField onChange={(e) => setMessage(e.target.value)} value={message} rows={4} multiline />
              <ButtonBox>
                <Button onClick={sendForm} disabled={isLoading ? true : false} className="active">
                  {isLoading ? <Loader color="#fff" width="25px" height="25px" /> : <span>Send Request</span>}
                </Button>
                <Button disabled={false} onClick={() => setVisible(false)}>
                  Cancel
                </Button>
              </ButtonBox>
            </>
        }
      </StyledBox>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  & .MuiBackdrop-root {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: 0px 12px 33.4221px rgba(21, 49, 156, 0.1);
  max-width: 512px;
  padding: 5px 35px;
  border-radius: 8px;
  width: 90%;
  margin: 0 auto;
  height: 310px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  > * {
    width: 100%;
  }
  & .MuiButtonBase-root.MuiButton-root {
    height: 38px;
    width: 115px;
    margin-right: 10px;
  }
`;

const Styledh3 = styled.h3`
  text-align: left;
  margin: 18px;
`;

const Styledh5 = styled.h5`
  text-align: left;
  margin: 8px 0;
  font-weight: normal;
`;

const StyledTextField = styled(TextField)``;

const ButtonBox = styled(Box)`
  margin-top: 20px;
`;

const StyledStatus = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30%;
`;
