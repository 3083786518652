import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import Service from '../API/service';
import Formatter from '../helpers/format';
import { ISlice, TimetableTypeEnum } from '../models/ISlice';
import { useActions } from './useActions';
import { useTypedSelector } from './useTypedSelector';

const DEFAULT_SLICE: ISlice = {
  name: '',
  allocation: 0,
  to_funding_sources: '',
  timetable_type: TimetableTypeEnum.DAILY,
  timetable_value: null,
  amount_due: 0,
  color: '#22C55E',
  transfers_amount: null
};

export const useEditSlices = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { loadSlices } = useActions();
  const [title, setTitle] = useState('Add New Slice');
  const [slice, setSlice] = useState(DEFAULT_SLICE);
  const { slices, isLoading, error } = useTypedSelector(({ slices }) => slices);
  const [showRemidnerModal, setShowRemidnerModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Error while loading slices. Please try again later.', {
        variant: 'error'
      });
    }
  }, [error]);

  function edit(index: number) {
    setSlice(Object.assign({}, slices[index]));
    setTitle('Edit Slice Settings');
    setShowRemidnerModal(true);
  }

  function addNew() {
    setSlice(Object.assign({}, DEFAULT_SLICE));
    setTitle('Add New Slice');
    setShowRemidnerModal(true);
  }

  async function save(): Promise<void> {
    try {
      const data: ISlice = Object.assign({}, slice);
      if (slice.timetable_type === TimetableTypeEnum.WEEKLY && slice.timetable_value === null) {
        data.timetable_value = Formatter.date(new Date());
      }
      if (slice.timetable_type === TimetableTypeEnum.DAILY) {
        data.timetable_value = null;
      }
      slice.id ? await Service.updateSlice(data) : await Service.createSlice(data);
      setShowRemidnerModal(false);
      loadSlices();
    } catch (e: any) {
      if (e.response.status === 422) {
        setErrorMessage(e.response.data.message);
        return;
      }

      enqueueSnackbar('Something went wrong. Please try again later', {
        variant: 'error'
      });
    }
  }

  async function deleteSlice(slice: ISlice, cb?: () => void) {
    let confirmed = window.confirm('Are you sure that you want to remove this slice?');
    if (confirmed && slices.length) {
      try {
        await Service.deleteSlice(slice);
        loadSlices();
        if (typeof cb === 'function') {
          cb();
        }
      } catch (e) {
        enqueueSnackbar('Something went wrong. Please try again later', {
          variant: 'error'
        });
      }
    }
  }

  function close() {
    setErrorMessage('');
    setSlice(Object.assign({}, DEFAULT_SLICE));
    setShowRemidnerModal(false);
  }

  return {
    edit,
    deleteSlice,
    setSlice,
    addNew,
    save,
    close,
    slices,
    showRemidnerModal,
    errorMessage,
    slice,
    title,
    isLoading
  };
};
