import auth from './auth';
import bankAccounts from './bankAccounts';
import dwolla from './dwolla';
import slices from './slices';
import transaction from './transaction';

export default {
  auth,
  bankAccounts,
  dwolla,
  slices,
  transaction,
};
