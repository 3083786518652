export interface ISlice {
  id?: number;
  name: string;
  allocation: number;
  color: string;
  to_funding_sources: string;
  timetable_type: string;
  timetable_value: string | null;
  amount_due: number;
  transfers_amount: null | number;
}

export enum TimetableTypeEnum {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export enum Days {
  Su = 0,
  Mo = 1,
  Tu = 2,
  We = 3,
  Th = 4,
  Fr = 5,
  Sa = 6,
}
