import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AddIcon from '@mui/icons-material/Add';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import { Button } from '@mui/material';
import { FC, memo, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import Service from '../../API/service';
import background from '../../assets/images/dashboard_income.png';
import Formatter from '../../helpers/format';
import { useAddAccount } from '../../hooks/useAddAccount';
import { useTypedSelector } from '../../hooks/useTypedSelector';

export interface Props {}

enum Intervals {
  THIS_MONTH = 'This month',
  LAST_30 = '30 days',
  LAST_90 = '90 days'
}

export const DashboardIncome: FC<Props> = memo(() => {
  const d: Date = new Date();
  d.setDate(1);
  const [startDate, setStartDate] = useState(d);
  const [endDate, setEndDate] = useState(new Date());
  const [moneyIn, setMoneyIn] = useState(0);
  const [moneyOut, setMoneyOut] = useState(0);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const { open, ready } = useAddAccount();
  const { defaultAccount } = useTypedSelector(({ bankAccounts }) => bankAccounts);

  function toggleCollapse() {
    setIsCollapsed(!isCollapsed);
  }

  useEffect(() => {
    if (!(startDate || endDate)) {
      return;
    }
    Service.getTransfersAmount(startDate, endDate)
      .then(({ data }) => {
        setMoneyIn(data.moneyIn);
        setMoneyOut(data.moneyOut);
      })
      .catch(e => console.error(e));
  }, [startDate, endDate]);

  function addAccount() {
    open();
  }

  return (
    <StyledContainer className={isCollapsed ? 'collapsed' : ''}>
      <StyledHeader>
        <div>Income Account</div>
        <div>
          {Object.entries(Intervals).map(([key, value]) => {
            const d = new Date();
            let className = '';
            if (value === Intervals.THIS_MONTH) {
              d.setDate(1);
            }
            if (value === Intervals.LAST_30) {
              d.setDate(d.getDate() - 30);
            }
            if (value === Intervals.LAST_90) {
              d.setDate(d.getDate() - 90);
            }
            const start = Formatter.date(startDate);
            const current = Formatter.date(d);
            const now = Formatter.date(new Date());
            const end = endDate ? Formatter.date(endDate) : '';
            if (start === current && now === end) {
              className = 'active';
            }
            const width = key === Intervals.LAST_30 ? '87px' : '70px';

            return (
              <Button
                key={key}
                sx={{ width }}
                className={className}
                onClick={() => {
                  setStartDate(d);
                  setEndDate(new Date());
                }}>
                {value}
              </Button>
            );
          })}
          <DatePicker
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={onChange}
            selectsRange
            customInput={
              <Button sx={{ width: '27px' }}>
                <CalendarMonthIcon />
              </Button>
            }
          />
          <StyledCollapsedButton sx={{ width: '27px' }} className='active'>
            {isCollapsed ? (
              <KeyboardArrowDownIcon onClick={toggleCollapse} />
            ) : (
              <KeyboardArrowUpIcon onClick={toggleCollapse} />
            )}
          </StyledCollapsedButton>
        </div>
      </StyledHeader>
      <StyledBody collapsed={isCollapsed}>
        {defaultAccount.dwolla_url !== undefined ? (
          <div>
            <AccountBalanceWalletIcon sx={{ mr: 1 }} />
            <div>Balance</div>
            <div>{Formatter.money(defaultAccount.balance ?? 0)}</div>
          </div>
        ) : (
          <div className='no-account'>
            <AccountBalanceWalletIcon />
            <div onClick={addAccount}>
              <AddIcon
                sx={{
                  fontSize: isCollapsed ? '28px' : '40px',
                  marginBottom: isCollapsed ? '0' : '10px',
                  transition: 'margin 0.5s linear, font-size 0.5s linear'
                }}
              />
              Add income account
            </div>
          </div>
        )}
        <div>
          <SouthEastIcon sx={{ mr: 1 }} />
          <div>Money In</div>
          <div>{Formatter.money(moneyIn)}</div>
        </div>
        <div>
          <NorthEastIcon sx={{ mr: 1 }} />
          <div>Money Out</div>
          <div>{Formatter.money(moneyOut)}</div>
        </div>
      </StyledBody>
      <StyledFooter>
        <div>
          <AccessTimeIcon />
          Last Updateted : {Formatter.date(new Date(), 'long')}
        </div>
        <div>
          <AccountBalanceIcon />
          Bank Name : {defaultAccount.bankName ?? 'N/A'}
        </div>
      </StyledFooter>
    </StyledContainer>
  );
});

const StyledContainer = styled.div`
  background: url(${background});
  background-color: #eaedfc;
  background-position: bottom right;
  background-repeat: no-repeat;
  padding-bottom: 24px;
  &.collapsed {
    padding-bottom: 14px;
    transition: padding-bottom 0.5s linear;
  }
`;

const StyledHeader = styled.div`
  margin-bottom: 18px;
  font-size: 18px;
  padding: 10px 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .MuiButtonBase-root {
    text-transform: none;
    min-width: auto;
    margin: 0 5px;
    background: #adbcf4;
    color: #1c3fc8;
    font-size: 12px;
    padding: 0;
    height: 25px;
  }
  & .MuiSvgIcon-root {
    font-size: 17px;
  }
  & .react-datepicker-wrapper {
    width: auto;
  }
  & .react-datepicker__tab-loop {
    position: absolute;
    top: 0;
    right: 0;
  }
  & .react-datepicker__day--keyboard-selected {
    background: inherit;
    color: inherit;
  }
  & .react-datepicker__day--in-range {
    color: #fff;
    background-color: #216ba5;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
    & > :first-child {
      margin-bottom: 8px;
    }
    & .MuiButtonBase-root {
      margin-left: 0;
      margin-right: 8px;
    }
  }
`;

const StyledBody = styled.div<{ collapsed?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #fff;
  margin-bottom: 33px;
  & > div {
    max-width: 250px;
    width: 80%;
    margin: 0 10px;
    height: ${({ collapsed }) => (collapsed ? '65px' : '130px')};
    transition: height 0.5s linear;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: ${({ collapsed }) => (collapsed ? 'row' : 'column')};
    align-items: ${({ collapsed }) => (collapsed ? 'center' : 'flex-start')};
    justify-content: ${({ collapsed }) => (collapsed ? 'start' : 'space-between')};
    & > :nth-child(1) {
      background: rgba(0, 0, 0, 0.3);
      border-radius: 4px;
      width: 30px;
      height: 30px;
      padding: 6px;
    }
    & > :nth-child(2) {
      font-weight: 400;
      font-size: ${({ collapsed }) => (collapsed ? '0px' : '14px')};
      transition: font-size 0.2s linear;
    }
    & > :nth-child(3) {
      font-weight: 600;
      font-size: ${({ collapsed }) => (collapsed ? '18px' : '28px')};
      line-height: 1.4;
      transition: font-size 0.2s linear;
    }
  }
  & .no-account {
    position: relative;
    & > :nth-child(2) {
      display: flex;
      align-items: center;
      flex-direction: ${({ collapsed }) => (collapsed ? 'row' : 'column')};
      font-size: 12px;
      margin: auto;
    }
  }
  & > :nth-child(1) {
    background: #3458e3;
  }
  & > :nth-child(2) {
    background: #37b697;
  }
  & > :nth-child(3) {
    background: #ff6676;
  }

  @media (max-width: 900px) {
    margin-bottom: 10px;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 10px;
    & > div {
      width: 100%;
      max-width: none;
      margin: 0 0 10px;
    }
  }
`;

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  & svg {
    font-size: 18px;
    margin-right: 5px;
  }
  & div {
    display: flex;
    align-items: center;
    margin: 0 15px;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StyledCollapsedButton = styled(Button)`
  @media (max-width: 375px) {
    &.MuiButtonBase-root {
      display: none;
    }
  }
`;
