import { Box, Button } from '@mui/material';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import Formatter from '../../../helpers/format';
import { Days } from '../../../models/ISlice';

function calcDate(day: number): Date {
  const now: Date = new Date();
  const curDay: number = now.getDay();
  now.setDate(now.getDate() + day - curDay);
  return now;
}
interface WeeklyProps {
  onClick: (date: string | null) => void;
  timetable_value: string | null;
}

export const Weekly: FC<WeeklyProps> = ({ onClick, timetable_value }) => {
  return (
    <StyledContainer>
      <Styledh5>Every week on</Styledh5>
      <StyledButtons>
        {Object.entries(Days)
          .filter(([key]) => Number(key) >= 0)
          .map(([day, value]) => {
            const now: Date = calcDate(Number(day));
            return (
              <Button
                key={`Dayname_${day}`}
                className={
                  new Date(timetable_value ?? Date()).getDay() === now.getDay()
                    ? 'active'
                    : ''
                }
                value={Formatter.date(now)}
                onClick={(e: any) => onClick(e.target.value)}
              >
                {value}
              </Button>
            );
          })}
      </StyledButtons>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)``;

interface StyledProps {
  justifyContent?: string;
}

const StyledButtons = styled(Box)<StyledProps>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'space-between'};
  width: 100%;
  margin: 5px auto 10px
    ${({ justifyContent }) => (justifyContent ? '-10px;' : '')};
  & .MuiButtonBase-root.MuiButton-root {
    height: 34px;
    min-width: 48px;
    border: 1px solid #cbd5e1;
    border-radius: 3px;
    background-color: transparent;
    color: #475569;
    font-size: 14px;
    ${({ justifyContent }) => (justifyContent ? 'margin-left: 10px;' : '')}
    &.active {
      background: #334155;
      color: #f8fafc;
      cursor: default;
    }
  }
`;

const Styledh5 = styled.h5`
  text-align: left;
  margin: 2px 0;
  font-weight: normal;
`;
