import { Box } from '@mui/material';
import React, { FC } from 'react';
import styled from 'styled-components';
import Formatter from '../../helpers/format';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { IncomeAccountSelector } from './IncomeAccountSelector';

export const SlicesIncomeAccount: FC = () => {
  const { defaultAccount } = useTypedSelector(({ bankAccounts }) => bankAccounts);
  return (
    <StyledContainer>
      <HeaderBox>
        <h3>Income Account</h3>
        <Box>{Formatter.money(defaultAccount.balance ?? 0)}</Box>
      </HeaderBox>
      <BodyBox>
        <h3>Linked Account</h3>
        <IncomeAccountSelector />
        <Box>**Must be a registered business bank account</Box>
      </BodyBox>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  background: #1c3fc8;
  color: #fff;
  padding: 10px 20px;
  border: 1px solid #859aee;
  border-radius: 5px;
  min-height: 137px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const HeaderBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  line-height: 20px;
  height: 30px;
  margin-bottom: 8px;
  & h3 {
    color: #adbcf4;
    font-weight: 600;
    font-size: 18px;
    margin: 0;
  }
`;
const BodyBox = styled(Box)`
  & h3 {
    color: #adbcf4;
    font-weight: normal;
    font-size: 12px;
    margin: 0 0 8px;
  }
  & .MuiSelect-select {
    background: #fff;
    color: #64748b;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  & .MuiBox-root {
    font-size: 10px;
    line-height: 12px;
    font-weight: normal;
    color: #fbeb62;
    margin-top: 6px;
  }
`;
