import React, { FC, useState } from 'react';
import styled from 'styled-components';
import BoltIcon from '@mui/icons-material/Bolt';
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { Loader } from '../UI/Loader';
import { IBankAccount } from '../../models/IBankAccount';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import { StyledAlert } from './StyledAlert';
import useTransfer from '../../hooks/useTransfer';
import { ConfirmModal } from '../parts/ConfirmModal';

export interface Props {}

export const QuickTransfer: FC<Props> = () => {
  const {
    error,
    successMessage,
    isTransferSending,
    isLoading,
    transfer,
    accounts,
    setTransferFrom,
    setTransferTo,
    setAmount,
    sendTransfer
  } = useTransfer();

  const [confirmModal, setConfirmModal] = useState(false);
  function handleSubmit(e: any) {
    e.preventDefault();
    setConfirmModal(true);
  }

  function confirm() {
    setConfirmModal(false);
    sendTransfer();
  }

  return (
    <form onSubmit={handleSubmit}>
      <StyledContainer>
        <StyledHeader>
          <StyledBoltIcon /> Quick Transfer
        </StyledHeader>

        <FormControl size='small'>
          <InputLabel id='select-from'>From</InputLabel>
          <Select
            labelId='select-from'
            value={isLoading ? '' : transfer.fromAccount}
            label='From'
            name='fromAccount'
            onChange={e => setTransferFrom(e.target.value)}
            displayEmpty
            required>
            {accounts.map((account: IBankAccount, n) => (
              <MenuItem key={`${account.dwolla_url}-${n}`} value={account.dwolla_url}>
                {account.bankName}***{account.last4}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size='small'>
          <InputLabel id='select-to'>To</InputLabel>
          <Select
            labelId='select-to'
            value={isLoading ? '' : transfer.toAccount}
            label='To'
            name='toAccount'
            onChange={e => setTransferTo(e.target.value)}
            displayEmpty
            required>
            {accounts
              .filter(account => account.dwolla_url !== transfer.fromAccount)
              .map((account: IBankAccount, n) => (
                <MenuItem key={`${account.dwolla_url}-${n}`} value={account.dwolla_url}>
                  {account.bankName}***{account.last4}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <TextField
          required
          size='small'
          label='Amount'
          value={transfer.amount}
          name='amount'
          onChange={e => setAmount(e.target.value)}
          InputProps={{
            inputProps: {
              min: 0,
              step: 'any',
              type: 'number'
            },
            endAdornment: (
              <InputAdornment position='end'>
                <MoneyIcon sx={{ fontSize: '15px' }} />
              </InputAdornment>
            )
          }}
        />
        {successMessage && (
          <StyledAlert sx={{ mt: 1 }} icon={false} severity='success'>
            {successMessage}
          </StyledAlert>
        )}
        {error && (
          <StyledAlert icon={false} severity='error'>
            {error}
          </StyledAlert>
        )}
        <Button disabled={isTransferSending} type='submit' className='active'>
          {isLoading || isTransferSending ? (
            <Loader color='#fff' width='25px' height='25px' />
          ) : (
            'Transfer Money'
          )}
        </Button>
      </StyledContainer>
      {confirmModal && <ConfirmModal setVisible={setConfirmModal} onConfirm={confirm} />}
    </form>
  );
};

const StyledContainer = styled.div`
  user-select: none;
  height: 390px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  & .MuiButtonBase-root,
  & .MuiFormControl-root {
    margin-top: 35px;
    width: 90%;
    max-width: 361px;
  }
  & .MuiAlert-root {
    position: absolute;
    top: 286px;
    margin-top: 8px;
  }
`;

const StyledHeader = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #1c3fc8;
  max-width: 361px;
  width: 90%;
  height: 45px;
  background: #f1f5f9;
  border-radius: 5px;
  margin: 20px auto 0;
  display: flex;
  align-items: center;
`;

const StyledBoltIcon = styled(BoltIcon)`
  color: #ffee93;
  border-radius: 8px;
  background: #1236b8;
  margin: 0 10px;
`;
