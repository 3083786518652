import React, { FC } from 'react';
import { ThemeProvider } from '@mui/material';
import { AppRouter } from './components/AppRouter';
import { theme } from './theme';
import { BrowserRouter } from 'react-router-dom';
import { useTypedSelector } from './hooks/useTypedSelector';
import { SnackbarProvider } from 'notistack';
import startIdleTimeout from './helpers/idleTimeout';
import Service from './API/service';

const App: FC<{}> = () => {
    const { isAuth } = useTypedSelector(state => state.auth);

    const handleLogout = async () => {
        await Service.logout();
    };


    const handleIdleTimeout = async () => {
        await handleLogout();
    };

    if (isAuth) {
        startIdleTimeout(handleIdleTimeout);
    }

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
