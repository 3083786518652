import { FC } from 'react';
import styled from 'styled-components';
import protectImg from '../../../assets/images/landing/protect.png';
import okIconImg from '../../../assets/images/landing/ok-icon.svg';

interface Props {}

export const Protect: FC<Props> = ({}) => {
  return (
    <StyledModule>
      <img style={{ marginLeft: '-100px' }} src={protectImg} alt='protect' />
      <StyledText>
        <StyledTextH>All your personal and Business accounts are 100% secure with us</StyledTextH>
        <StyledTextList>
          <StyledTextListItem>
            <img src={okIconImg} alt='ok' />
            Secure Transaction
          </StyledTextListItem>
          <StyledTextListItem>
            <img src={okIconImg} alt='ok' />
            Credential Security
          </StyledTextListItem>
          <StyledTextListItem>
            <img src={okIconImg} alt='ok' />
            Account Privacy
          </StyledTextListItem>
        </StyledTextList>
      </StyledText>
    </StyledModule>
  );
};

const StyledModule = styled.div`
  background: #eaedfc;
  height: 255px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  @media (max-width: 1200px) {
    padding: 0 5%;
    & > img {
      margin: 0 !important;
      width: 20%;
    }
  }
  @media (max-width: 900px) {
    & > img {
      display: none;
    }
  }
`;

const StyledText = styled.div`
  width: 750px;
  margin-left: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 1200px) {
    margin-left: 30px;
    width: 80%;
  }
  @media (max-width: 900px) {
    margin-left: 0px;
    width: 100%;
  }
`;
const StyledTextH = styled.h3`
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  color: #0f236f;
  margin: 0 0 40px;
  @media (max-width: 1200px) {
    font-size: 30px;
    line-height: 35px;
  }
  @media (max-width: 900px) {
    font-size: 25px;
    line-height: 30px;
    margin: 0 0 20px;
  }
`;
const StyledTextList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
    & > * {
      margin: 10px;
    }
  }
`;
const StyledTextListItem = styled.div`
  margin-right: 21px;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    margin-right: 5px;
  }
`;
