import { useState } from 'react';
import Service from '../API/service';
import { IBankAccount } from '../models/IBankAccount';
import { useActions } from './useActions';
import { useTypedSelector } from './useTypedSelector';

const DEFAULT_TRANSFER = {
  fromAccount: '',
  toAccount: '',
  amount: ''
};

const useTransfer = () => {
  const { isLoading, accounts } = useTypedSelector(({ bankAccounts }) => bankAccounts);
  const { loadSlices } = useActions();
  const [transfer, setTransfer] = useState(DEFAULT_TRANSFER);

  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isTransferSending, setIsTransferSending] = useState(false);

  function setTransferFrom(dwollaUrl: string) {
    if (dwollaUrl === transfer.toAccount) {
      setTransfer({ ...transfer, fromAccount: dwollaUrl, toAccount: '' });
    }
    setTransfer({ ...transfer, fromAccount: dwollaUrl });
  }
  function setTransferTo(dwollaUrl: string) {
    setTransfer({ ...transfer, toAccount: dwollaUrl });
  }
  function setAmount(amount: string) {
    setTransfer({ ...transfer, amount: amount });
  }

  function sendTransfer() {
    setIsTransferSending(true);

    const sourceAccount: IBankAccount | undefined = accounts.find(
      account => account.dwolla_url === transfer.fromAccount
    );
    const destAccount: IBankAccount | undefined = accounts.find(
      account => account.dwolla_url === transfer.toAccount
    );
    if (sourceAccount === undefined) {
      setIsTransferSending(false);
      setError('Select From Account');
      return;
    }
    if (destAccount === undefined) {
      setIsTransferSending(false);
      setError('Select To Account');
      return;
    }

    if (!transfer.amount || Number(transfer.amount) <= 0) {
      setIsTransferSending(false);
      setError('Invalid Amount');
      return;
    }

    return Service.quickTransfer({
      source_link_index: sourceAccount.linkIndex,
      source_account_index: sourceAccount.accountIndex,
      destination_link_index: destAccount.linkIndex,
      destination_account_index: destAccount.accountIndex,
      amount: Number(transfer.amount)
    })
      .then(data => {
        setError('');
        setSuccessMessage('Your money was successfully transferred.');
        setTransfer(Object.assign(DEFAULT_TRANSFER));
        loadSlices();
        return data;
      })
      .catch((e: any) => {
        setSuccessMessage('');
        if (e.response.status === 422) {
          setError(e.response.data.message);
          return Promise.reject(e);
        }
        setError('Something went wrong. Please try again later.');
        return Promise.reject(e);
      })
      .finally(() => setIsTransferSending(false));
  }

  return {
    error,
    successMessage,
    isTransferSending,
    isLoading,
    transfer,
    accounts,
    setTransferFrom,
    setTransferTo,
    setAmount,
    sendTransfer
  };
};

export default useTransfer;
