import React, { FC, useEffect, useState } from 'react';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';
import Service from '../../../API/service';
import styled from 'styled-components';
import { Button, Box, MenuItem } from '@mui/material';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import ListSubheader from '@mui/material/ListSubheader';
import { Loader } from '../../UI/Loader';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { TextFieldStates } from '../../UI/TextFieldStates';
import Checkbox from '@mui/material/Checkbox';
import { Controller } from './Controller';
import { Beneficial } from './Beneficial';
import { IDwollaBeneficial } from '../../../models/IDwollaCustomer';
import { useSnackbar } from 'notistack';

export const AccountForm: FC<{}> = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { customer, beneficials, disableList, controllerDisable } = useTypedSelector(
    state => state.dwolla
  );
  const [errors, setErrors]: any = useState({});
  const [beneficialErrors, setBeneficialErrors]: any = useState([]);
  const {
    updateDwollaCustomer,
    updateDwollaCustomerController,
    updateDwollaCustomerControllerAddress,
    updateDwollaCustomerControllerPassport,
    loadDwollaCustomer
  } = useActions();
  const [checkedTerms, setCheckedTerms] = useState(true);
  const [checkedCorrect, setCheckedCorrect] = useState(true);
  const [isTransferSending, setTransferSending] = useState(false);
  const [businessClassificationArr, setBusinessClassificationArr]: any[] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    loadDwollaCustomer();

    Service.getBusinessClassification().then(res => {
      setBusinessClassificationArr([...res.data]);
      setIsLoading(false);
    });
  }, []);

  function camelToUnderscore(key: string) {
    var result = key.replace(/([A-Z])/g, ' $1');
    return result.split(' ').join('_').toLowerCase();
  }

  function parseErrors(errors: []) {
    errors = errors.reduce((obj: any, item: any) => {
      const key = camelToUnderscore(item.path.replaceAll('/', '.').substring(1));
      if (obj[key]) {
        obj[key].push(item.message);
      } else {
        obj[key] = [item.message];
      }
      return obj;
    }, {});
    return errors;
  }

  function sendData(event: any) {
    setTransferSending(true);
    setErrors({});
    setBeneficialErrors([]);
    event.preventDefault();

    Service.updateDwollaCustomer(controllerDisable ? { ...customer, controller: null } : customer)
      .then(res => {
        enqueueSnackbar('Account information updated', {
          variant: 'success'
        });
        loadDwollaCustomer();

        if (beneficials.length) {
          let arr = [...beneficialErrors];
          beneficials.map((beneficial: IDwollaBeneficial, i: number) => {
            Service.addBeneficialOwner(beneficial)
              .then(e => {
                enqueueSnackbar(
                  `Beneficial owner ${beneficial.first_name} ${beneficial.last_name} updated`,
                  {
                    variant: 'success'
                  }
                );
              })
              .catch((e: any) => {
                enqueueSnackbar(
                  `Beneficial owner ${beneficial.first_name} ${beneficial.last_name} not updated`,
                  {
                    variant: 'error'
                  }
                );

                const errors = e.response.data;
                if (errors.length) {
                  arr[i] = parseErrors(errors);
                }
                setBeneficialErrors(arr);
              });
          });
        }
        setTransferSending(false);
      })
      .catch((e: any) => {
        enqueueSnackbar('Account information not updated', {
          variant: 'error'
        });

        const errors = e.response.data;
        if (errors.length) {
          setErrors(parseErrors(errors));
        }

        setTransferSending(false);
      });
  }

  if (isLoading || !customer.hasOwnProperty('id')) {
    return (
      <StyledLoader>
        <Loader />
      </StyledLoader>
    );
  }

  return (
    <StyledModule>
      <Box
        onSubmit={sendData}
        component='form'
        sx={{
          '& .MuiInputBase-input': {
            color: '#2563EB'
          },
          '& .MuiFormLabel-root': {
            color: '#64748B'
          },
          '& .MuiPaper-root, & > .MuiTextField-root, & div .MuiFormGroup-root': {
            xs: { margin: '10px 5px', width: 'calc(100% - 10px)' },
            sm: { margin: '10px 5px', width: 'calc(100% - 10px)' },
            md: { margin: '2%', width: 'calc(100% - 4%)' },
            lg: { margin: '8px', width: 'calc(100% - 16px)' }
          },
          '& div .MuiTextField-root': {
            xs: { margin: '10px 5px', width: 'calc(100% - 10px)' },
            sm: { margin: '10px 5px', width: 'calc(100% - 10px)' },
            md: { margin: '2%', width: 'calc(100% - 4%)' },
            lg: { margin: '8px', width: 'calc(50% - 16px)' }
          }
        }}
        autoComplete='off'>
        <div>
          <TextField
            disabled={disableList.first_name}
            error={errors.first_name && true}
            helperText={errors.first_name}
            size='small'
            value={customer.first_name || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateDwollaCustomer({ first_name: e.target.value })
            }
            label='First Name'
            type='text'
            required
          />
          <TextField
            disabled={disableList.last_name}
            error={errors.last_name && true}
            helperText={errors.last_name}
            size='small'
            value={customer.last_name || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateDwollaCustomer({ last_name: e.target.value })
            }
            label='Last Name'
            type='text'
            required
          />
        </div>
        <div>
          <TextField
            error={errors.email && true}
            helperText={errors.email}
            size='small'
            value={customer.email || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateDwollaCustomer({ email: e.target.value })
            }
            label='Email'
            type='email'
            required
          />
          <TextField
            disabled={disableList.date_of_birth}
            error={errors.date_of_birth && true}
            helperText={errors.date_of_birth}
            size='small'
            value={customer.date_of_birth || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateDwollaCustomer({ date_of_birth: e.target.value })
            }
            label='Date Of Birth'
            placeholder='YYYY-MM-DD'
            type='text'
            required
          />
        </div>

        <TextField
          error={errors.address1 && true}
          helperText={errors.address1}
          size='small'
          value={customer.address1 || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateDwollaCustomer({ address1: e.target.value })
          }
          label='Address 1'
          type='text'
          required
        />
        <TextField
          error={errors.address2 && true}
          helperText={errors.address2}
          size='small'
          value={customer.address2 || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            updateDwollaCustomer({ address2: e.target.value })
          }
          label='Address 2'
          type='text'
        />
        <div>
          <TextField
            error={errors.city && true}
            helperText={errors.city}
            size='small'
            value={customer.city || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateDwollaCustomer({ city: e.target.value })
            }
            label='City'
            type='text'
            required
          />
          <TextFieldStates
            error={errors.state && true}
            helperText={errors.state}
            state={customer.state || ''}
            setState={(value: string) => updateDwollaCustomer({ state: value })}
          />
        </div>
        <div>
          <TextField
            error={errors.postal_code && true}
            helperText={errors.postal_code}
            size='small'
            value={customer.postal_code || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateDwollaCustomer({ postal_code: e.target.value })
            }
            label='Postal Code'
            type='text'
            required
          />
        </div>

        <div>
          <TextField
            disabled={disableList.business_type}
            error={errors.business_type && true}
            helperText={errors.business_type}
            size='small'
            value={
              customer.business_type && customer.business_type_name
                ? `${customer.business_type}:${customer.business_type_name}`
                : ''
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const arr = e.target.value.split(':');
              const businessType = arr[0];
              const businessTypeName = arr[1];
              if (businessType === 'soleProprietorship') {
                updateDwollaCustomerController(null);
              }

              updateDwollaCustomer({
                business_type: businessType,
                business_type_name: businessTypeName
              });
            }}
            select
            label='Business Type'
            required>
            <MenuItem value='soleProprietorship:Sole Proprietorship'>Sole Proprietorship</MenuItem>
            <MenuItem value='soleProprietorship:Unincorporated association'>
              Unincorporated association
            </MenuItem>
            <MenuItem value='soleProprietorship:Trust'>Trust</MenuItem>
            <MenuItem value='corporation:Corporation'>Corporation</MenuItem>
            <MenuItem value='corporation:Publicly traded corporations'>
              Publicly traded corporations
            </MenuItem>
            <MenuItem value='corporation:Non-profits'>Non-profits</MenuItem>
            <MenuItem value='llc:LLCs'>LLCs</MenuItem>
            <MenuItem value="partnership:Partnerships, LP's, LLP's">
              Partnerships, LP's, LLP's
            </MenuItem>
          </TextField>
        </div>

        <div>
          <TextField
            disabled={disableList.business_name}
            error={errors.business_name && true}
            helperText={errors.business_name}
            size='small'
            value={customer.business_name || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateDwollaCustomer({ business_name: e.target.value })
            }
            label='Business Name'
            type='text'
            required
          />
          <TextField
            disabled={disableList.business_classification}
            error={errors.business_classification && true}
            helperText={errors.business_classification}
            size='small'
            value={customer.business_classification || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateDwollaCustomer({ business_classification: e.target.value })
            }
            label='Business Classification'
            type='text'
            select
            required>
            {businessClassificationArr.map((item: any) => {
              return [
                <StyledListSubheader>{item.name}</StyledListSubheader>,
                item._embedded['industry-classifications'].map((p: any) => {
                  return (
                    <StyledMenuItem key={p.id} value={p.id}>
                      {p.name}
                    </StyledMenuItem>
                  );
                })
              ];
            })}
          </TextField>
        </div>
        <div>
          <TextField
            disabled={disableList.ssn}
            error={errors.ssn && true}
            helperText={errors.ssn}
            size='small'
            value={customer.ssn || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateDwollaCustomer({ ssn: e.target.value })
            }
            label='SSN'
            type='text'
          />
          <TextField
            disabled={disableList.ein}
            error={errors.ein && true}
            helperText={errors.ein}
            size='small'
            value={customer.ein || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              updateDwollaCustomer({ ein: e.target.value })
            }
            label='EIN'
            type='text'
          />
        </div>

        {customer.business_type && customer.business_type !== 'soleProprietorship' && (
          <Controller
            disableForm={controllerDisable}
            hideList={controllerDisable ? ['date_of_birth', 'passport'] : []}
            updateCustomer={updateDwollaCustomerController}
            updateCustomerAddress={updateDwollaCustomerControllerAddress}
            updateCustomerPassport={updateDwollaCustomerControllerPassport}
            errors={
              Object.keys(errors)
                .filter((key: string) => key.search('controller') === 0)
                .reduce((obj: any, key: string) => {
                  obj[key.replace('controller.', '')] = errors[key];
                  return obj;
                }, {}) || {}
            }
            customer={customer.controller || {}}
            title='Controller'
          />
        )}

        {customer.business_type_name &&
          (customer.business_type_name === 'Corporation' ||
            customer.business_type_name === 'LLCs' ||
            customer.business_type_name === "Partnerships, LP's, LLP's") && (
            <Beneficial errors={beneficialErrors} customer={customer} />
          )}

        <FormGroup sx={{ p: 1 }}>
          <FormControlLabel
            control={
              <Checkbox defaultChecked={true} onChange={e => setCheckedCorrect(e.target.checked)} />
            }
            label='I hereby certify, to the best of my knowledge, that the information provided above is complete and correct.'
          />
        </FormGroup>

        <FormGroup sx={{ p: 1 }}>
          <FormControlLabel
            control={
              <Checkbox defaultChecked={true} onChange={e => setCheckedTerms(e.target.checked)} />
            }
            label={
              <>
                By checking this box you agree to{' '}
                <Link href={process.env.PUBLIC_URL + '/terms.html'} target='_blank'>
                  Our Terms of Service
                </Link>{' '}
                and{' '}
                <Link href={process.env.PUBLIC_URL + '/privacy.html'} target='_blank'>
                  Privacy Policy
                </Link>{' '}
                as well as our Vendor{' '}
                <Link href='https://www.dwolla.com/legal/tos/' target='_blank'>
                  Dwolla’s Terms of Service
                </Link>{' '}
                and{' '}
                <Link href='https://www.dwolla.com/legal/privacy/' target='_blank'>
                  Privacy Policy
                </Link>
              </>
            }
          />
        </FormGroup>

        <Button
          sx={{ margin: '20px 0' }}
          fullWidth
          disabled={!checkedTerms || !checkedCorrect}
          type='submit'
          className='active'>
          {isTransferSending ? <Loader color='#fff' width='25px' height='25px' /> : 'Save Changes'}
        </Button>
      </Box>
    </StyledModule>
  );
};

const StyledLoader = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
`;

const StyledModule = styled.div`
  & .MuiTypography-root {
    color: #64748b;
    font-size: 14px;
    line-height: 17px;
    font-weight: normal;
  }
`;
const StyledListSubheader = styled(ListSubheader)`
  &.MuiListSubheader-root {
    font-weight: bold;
    font-size: 16px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    padding-left: 40px;
    white-space: normal;
  }
`;
