import { FC, useState } from 'react';
import styled from 'styled-components';
import Link from '@mui/material/Link';
import logoImg from '../../../assets/images/landing/logo.png';
import copyIconImg from '../../../assets/images/landing/copy-icon.svg';
import { ContactModal } from '../../../components/parts/ContactModal';

interface Props {}

export const Footer: FC<Props> = ({}) => {
  const [contact, setContact] = useState(false);
  return (
    <StyledModule>
      <StyledModuleInfo>
        <img src={logoImg} alt='logo' />
        <StyledP>
          Aliquam non nisi a ipsum aliquam eleifend. Maecenas in sapien eget tortor posuere
          vulputate nec vitae dolor. Cras nec orci massa. Vivamus in lectus non dolor porta ornare.{' '}
        </StyledP>
        <StyledCopy>
          <img src={copyIconImg} alt='copy' />
          All rights reserved by Profit Pie. 2022
        </StyledCopy>
      </StyledModuleInfo>
      <StyledModuleMenu>
        <Link underline='none' href='#'>
          Home
        </Link>
        <Link underline='none' href='#'>
          About us
        </Link>
        <Link underline='none' href='#'>
          Pricing
        </Link>
        <Link underline='none' href='#'>
          Blog
        </Link>
        <Link
          onClick={e => {
            e.preventDefault();
            setContact(true);
          }}
          underline='none'
          href='#'>
          Contact
        </Link>
        <Link underline='none' href='#'>
          Terms & Condition and Privacy Policy
        </Link>
      </StyledModuleMenu>
      {contact && <ContactModal setVisible={setContact} />}
    </StyledModule>
  );
};

const StyledModule = styled.div`
  height: 350px;
  background: #0f236f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10%;
  @media (max-width: 1200px) {
    padding: 0 5%;
  }
`;

const StyledModuleInfo = styled.div`
  width: 380px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;
const StyledP = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #b8c6ff;
  margin: 30px 0;
`;
const StyledCopy = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-top: 40px;
  color: #ffffff;
  & > img {
    margin-right: 10px;
  }
`;
const StyledModuleMenu = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  flex-wrap: wrap;
  & > .MuiTypography-root {
    color: #ffffff;
    padding: 10px;
  }
  @media (max-width: 1200px) {
    flex-direction: column;

    & > .MuiTypography-root {
      padding: 12px;
      max-width: 130px;
    }
  }
`;
