import { createStore, combineReducers, applyMiddleware, AnyAction } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
import { composeWithDevTools } from '@redux-devtools/extension';

const appReducer = combineReducers(reducers);

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
}

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export type RootState = ReturnType<typeof store.getState>;
