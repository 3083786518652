import { Box, Button, ButtonGroup } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SlicesRemidnerModal } from './SlicesRemidnerModal';
import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import PaymentsIcon from '@mui/icons-material/Payments';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ClearIcon from '@mui/icons-material/Clear';
import { ISlice } from '../../models/ISlice';
import { Loader } from '../UI/Loader';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { IBankAccount } from '../../models/IBankAccount';
import Formatter from '../../helpers/format';
import { useEditSlices } from '../../hooks/useEditSlices';

interface AccountInfoProps {
  to_funding_sources: string;
  timetable_type: string;
  timetable_value: string;
  amount_due: number;
}

const AccountInfo: FC<AccountInfoProps> = ({ timetable_type, to_funding_sources }) => {
  const { accounts } = useTypedSelector(({ bankAccounts }) => bankAccounts);
  const [account, setAccount] = useState({} as IBankAccount);

  useEffect(() => {
    const found = accounts.find(
      (account: IBankAccount) => account.dwolla_url === to_funding_sources
    );
    if (found) {
      setAccount(found);
    }
  });

  function formatAmount(amount: number): string {
    let suffix = '';
    if (amount > 10 ** 3) {
      amount /= 10 ** 3;
      suffix = 'K';
    }
    if (amount > 10 ** 3) {
      amount /= 10 ** 3;
      suffix = 'M';
    }
    if (amount > 10 ** 3) {
      amount /= 10 ** 3;
      suffix = 'B';
    }
    return Formatter.money(amount) + suffix;
  }

  return (
    <>
      {Object.keys(account).length ? (
        <h5>
          <LinkIcon />
          {account.bankName ?? ''}**{account.last4}
        </h5>
      ) : (
        '-'
      )}
      <Box>
        <Box>
          <PaymentsIcon /> {formatAmount(account.balance ?? 0)}
        </Box>
        <Box>
          <NotificationsNoneIcon /> {timetable_type}
        </Box>
      </Box>
    </>
  );
};

export const SlicesList: FC = () => {
  const {
    edit,
    deleteSlice,
    setSlice,
    addNew,
    save,
    close,
    slices,
    showRemidnerModal,
    errorMessage,
    slice,
    title,
    isLoading
  } = useEditSlices();

  return (
    <StyledContainer>
      {slices.map((item: ISlice, index) => (
        <StyledBox bcolor={item.color || '#eee'} key={`${item.id}`}>
          <h3>{item.name}</h3>
          <h4>
            <Box>Allocation</Box>
            <Box>
              <>{item.allocation}%</>
            </Box>
          </h4>
          <AccountInfo {...(item as AccountInfoProps)} />

          <StyledButtonGroup aria-label='outlined primary button group'>
            <Button onClick={() => edit(index)}>
              <BorderColorIcon /> Edit
            </Button>
            <Button onClick={() => deleteSlice(item)}>
              <ClearIcon /> Delete
            </Button>
          </StyledButtonGroup>
        </StyledBox>
      ))}
      <StyledBox bcolor='#94a3b8' className='empty' onClick={addNew}>
        {isLoading ? <Loader color='#94a3b8' width='25px' height='25px' /> : <AddIcon />}
      </StyledBox>
      {showRemidnerModal && (
        <SlicesRemidnerModal
          setSlice={setSlice}
          slice={slice}
          title={title}
          cancel={close}
          save={save}
          deleteSlice={slice => {
            deleteSlice(slice, () => close());
          }}
          errorMessage={errorMessage}
        />
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  user-select: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  @media (max-width: 1536px) {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const StyledBox = styled(Box)<{ bcolor: string }>`
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  aspect-ratio: auto 1.2 / 1;
  padding: 20px;
  background: #ffffff;
  color: #475569;
  border-top: 4px solid ${({ bcolor }) => bcolor || '#eee'};
  border-radius: 5px;
  & .MuiSvgIcon-root {
    margin-right: 5px;
  }
  &.empty {
    background: transparent;
    border: 3px dashed ${({ bcolor }) => bcolor || '#eee'};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    & .MuiSvgIcon-root {
      margin: 0;
      font-size: 80px;
      color: ${({ bcolor }) => bcolor || '#eee'};
    }
  }
  & h3 {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    color: ${({ bcolor }) => bcolor || '#eee'};
  }
  & h4 {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    border-radius: 5px;
    padding: 10px;
    position: relative;
    overflow: hidden;
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background: ${({ bcolor }) => bcolor || '#eee'};
      opacity: 0.1;
      position: absolute;
      left: 0;
      top: 0;
    }
    & .MuiBox-root:nth-child(2) {
      color: #1e293b;
      font-weight: 500;
      font-size: 24px;
    }
  }
  & h5 {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  & > .MuiBox-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > .MuiBox-root {
      display: flex;
      align-items: center;
      line-height: 15px;
      width: 50%;
    }
  }
`;

const StyledButtonGroup = styled(ButtonGroup)`
  &.MuiButtonGroup-root {
    margin: 0;
    width: 100%;
    display: flex;
  }
  & .MuiButtonBase-root.MuiButton-root {
    font-size: 14px;
    border: 1px solid #e2e8f0;
    border-radius: 5px;
    background: #ffffff;
    color: #334155;
    width: 100%;
    height: 40px;
  }
`;
