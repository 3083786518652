import { Button } from '@mui/material';
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Service from '../../../API/service';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { Loader } from '../../UI/Loader';
import { Empty } from './Empty';
import { TransferList } from './TransferList';

export interface Props {}

export const DashboardMain: FC<Props> = memo(() => {
  const {
    slices,
    isLoading: isSlicesLoading,
    error: slicesError
  } = useTypedSelector(({ slices }) => slices);
  const [showActive, setShowActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const mShowActive = useMemo(() => showActive, [showActive]);
  const mIsLoading = useMemo(() => isLoading, [isLoading]);
  const setIsLoadingHandler = useCallback((loading: boolean) => {
    setIsLoading(loading);
  }, []);

  const hasSlices = (slices.length || isSlicesLoading) && !slicesError;

  if (hasSlices) {
    return (
      <StyledContainer>
        <TransferButton className={showActive ? 'active' : ''} onClick={() => setShowActive(true)}>
          Active Transfers
        </TransferButton>
        <TransferButton className={showActive ? '' : 'active'} onClick={() => setShowActive(false)}>
          Transfers History
        </TransferButton>
        <TransferList
          key={`${showActive}`}
          showActive={mShowActive}
          setIsLoading={setIsLoadingHandler}
          isLoading={mIsLoading}
        />
      </StyledContainer>
    );
  }
  return (
    <StyledContainerEmpty>
      <Empty isLoading={isLoading} />
    </StyledContainerEmpty>
  );
});

const TransferButton = styled(Button)`
  &.MuiButton-root {
    font-size: 14px;
    line-height: 1.4;
    padding: 8px 16px;
    margin-right: 15px;
  }
  @media (max-width: 650px) {
    &.MuiButton-root {
      margin: 2px;
    }
  }
`;

const StyledContainer = styled.div`
  height: 614px;
  padding: 28px 20px;
  @media (max-width: 650px) {
    padding: 5%;
  }
`;

const StyledContainerEmpty = styled.div`
  height: 546px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & img {
    margin-top: -20px;
  }
  & :nth-child(2) {
    margin-top: 13px;
    font-weight: 500;
    font-size: 18px;
    color: #000;
  }
  & :nth-child(3) {
    margin-top: 23px;
    color: #1c3fc8;
    font-weight: 500;
    font-size: 24px;
    display: flex;
    align-items: center;
  }
`;
