import { Alert } from '@mui/material';
import styled from 'styled-components';

export const StyledAlert = styled(Alert)`
  &.MuiAlert-root {
    font-size: 12px;
    width: fit-content;
    padding: 5px 10px;

    & .MuiAlert-message {
      padding: 0px;
    }

    & .MuiAlert-icon {
      color: #927922;
      width: 13.33px;
      height: 13.33px;
      padding: 1.83px;
      margin-right: 4.33px;

      & .MuiSvgIcon-root {
        width: 13.33px;
        height: 13.33px;
      }
    }
  }
`;
