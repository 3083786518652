import React, { FC, memo, useMemo } from 'react';
import styled from 'styled-components';
import { IUser } from '../../models/IUser';

interface Props {
  user: IUser;
  handleProfileMenuOpen?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const AccountPhoto: FC<Props> = memo(({ user, handleProfileMenuOpen }) => {
  return (
    <>
      {user.picture ? (
        <AccountPhotoImage
          onClick={handleProfileMenuOpen}
          src={user.picture}
          alt={user.given_name}
        />
      ) : (
        <EmptyPhoto>{`${user?.given_name[0] ?? ''}${user?.family_name[0] ?? ''}`}</EmptyPhoto>
      )}
    </>
  );
});

const EmptyPhoto = styled.div`
  background: #4e4e4e;
  width: 56px;
  height: 56px;
  color: #ebebeb;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20.47px;
  cursor: pointer;
  @media (max-width: 600px) {
    width: 40px;
    height: 40px;
  }
`;

const AccountPhotoImage = styled.img`
  border-radius: 50%;
  width: 56px;
  height: 56px;
  object-fit: cover;
  cursor: pointer;
  @media (max-width: 600px) {
    width: 40px;
    height: 40px;
  }
`;
