import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Chart } from 'react-google-charts';
import { useTypedSelector } from '../../hooks/useTypedSelector';

interface Props {}

export const DashboardPie: FC<Props> = ({}) => {
  const { slices } = useTypedSelector(({ slices }) => slices);
  const [data, setData] = useState([
    ['Slice', 'Amount'],
    ['No data', 100]
  ]);

  const [options, setOptions] = useState({
    width: 460,
    height: 440,
    pieHole: 0.4,
    is3D: false,
    legend: { position: 'bottom', alignment: 'center' },
    colors: ['#F7F7F7']
  });

  useEffect(() => {
    const filtered = slices.filter(slice => slice.allocation);
    const sliceArr = filtered.map(slice => [slice.name, Number(slice.allocation) ?? 0]);
    const colorArr = filtered.map(slice => slice.color);
    const sum = Number(
      filtered.reduce((accumulator, item) => item.allocation + accumulator, 0).toFixed(2)
    );

    if (sum < 100) {
      sliceArr.push(['No data', 100 - sum]);
      colorArr.push('#F7F7F7');
    }

    setData([['Slice', 'Hours per Day'], ...sliceArr]);
    setOptions({
      width: 460,
      height: 440,
      pieHole: 0.4,
      is3D: false,
      legend: { position: 'bottom', alignment: 'center' },
      colors: colorArr
    });
  }, [slices]);

  return (
    <StyledContainer>
      <Chart chartType='PieChart' data={data} options={options} />
    </StyledContainer>
  );
};

const StyledContainer = styled.div<Props>`
  overflow: hidden;
  height: 420px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;
