import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Service from '../../API/service';
import { useActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { AccountPhoto } from './AccountPhoto';
import { StyledAlert } from './StyledAlert';

export const AccountPhotoUpload: FC<{}> = () => {
  const { setUser, loadUser } = useActions();
  const dispatch = useDispatch();
  const { user } = useTypedSelector(state => state.auth);
  const [isImageUpload, setIsImageUpload] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  function onImageChange(e: ChangeEvent<HTMLInputElement>) {
    if (!e.target.files?.length) {
      return;
    }
    setIsImageUpload(true);
    setSuccessMessage('');
    const file = e.target.files[0];
    Service.uploadPhoto(file)
      .then(data => {
        setFormErrors([]);
        setSuccessMessage('Photo was updated!');
        dispatch(setUser(data.data));
      })
      .catch(e => {
        if (e.response.data.picture) {
          setFormErrors(e.response.data.picture);
        } else {
          setFormErrors(['Unkown error.'] as never);
        }
        setSuccessMessage('');
      })
      .finally(() => setIsImageUpload(false));
  }

  return (
    <Container>
      Your Photo
      {user && (
        <Avatar>
          <Caption>
            <Label>
              <AccountPhoto user={user} />
              <Small>
                <b>Upload new photo</b>
                <small>Max- 5 mb</small>
              </Small>
              <HiddenInput onChange={onImageChange} type='file' name='picture' />
            </Label>
            {successMessage && <StyledAlert severity='success'>{successMessage}</StyledAlert>}
            {formErrors.map((error: string, n: number) => (
              <StyledAlert key={n} severity='error' icon={false}>
                {error}
              </StyledAlert>
            ))}
            {isImageUpload && (
              <StyledAlert icon={false} severity='info'>
                Image uploading...
              </StyledAlert>
            )}
          </Caption>
        </Avatar>
      )}
    </Container>
  );
};

const Container = styled.div`
  font-size: 12px;
  font-weight: normal;
  margin-top: 18px;
  color: #64748b;
  @media (max-width: 900px) {
    margin-top: 0;
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

const Avatar = styled.div`
  display: flex;
  margin-top: 14px;
`;

const Caption = styled.div`
  margin: 6px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 80px;
`;

const Label = styled.label`
  cursor: pointer;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;

const Small = styled.span`
  font-size: 12px;
  color: #64748b;
  font-weight: normal;
  margin-left: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;
