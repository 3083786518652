import React, { FC, useEffect, useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useActions } from '../../hooks/useActions';
import { Loader } from '../UI/Loader';
import { IBankAccount } from '../../models/IBankAccount';
import { StyledAlert } from './StyledAlert';

export const IncomeAccountSelector: FC<{}> = () => {
  const { isLoading, accounts, defaultAccount, defaultAccountError } = useTypedSelector(
    (state) => state.bankAccounts
  );
  const { setDefaultBankAccount } = useActions();
  const [defautDwollaUrl, setDefautDwollaUrl] = useState(
    defaultAccount.dwolla_url ?? ''
  );
  function handleChangeBankAccount(e: any) {
    const { value } = e.target;
    if (value !== '') {
      setDefaultBankAccount(value);
    }
  }
  useEffect(() => {
    if ((defaultAccount?.dwolla_url ?? '') !== '') {
      setDefautDwollaUrl(defaultAccount.dwolla_url);
    }
  }, [defaultAccount]);

  return (
    <>
      <Select
        size="small"
        name="default_bank_account"
        fullWidth
        value={isLoading ? "" : defautDwollaUrl}
        onChange={handleChangeBankAccount}
        disabled={isLoading}
        displayEmpty
      >
        <MenuItem value="">
          {isLoading ? (
            <Loader color="#94a3b8" width="25px" height="25px" />
          ) : (
            'Select Income Account'
          )}
        </MenuItem>
        {accounts.map((account: IBankAccount, n) => (
          <MenuItem
            key={`${account.dwolla_url}-${n}`}
            value={account.dwolla_url}
          >
            {account.bankName}***{account.last4}
          </MenuItem>
        ))}
      </Select>
      {defaultAccountError && <StyledAlert icon={false} severity="error">{defaultAccountError}</StyledAlert>}
    </>
  );
};
