import { ISlice } from '../../../models/ISlice';

export interface SlicesState {
  slices: ISlice[];
  isLoading: boolean;
  error: string;
}

export enum SlicesActionsEnum {
  SET_SLICES = 'SET_SLICES',
  SET_IS_LOADING = 'SET_IS_LOADING_SLICES',
  SET_ERROR = 'SET_ERROR_SLICES',
}

export interface SetSlicesAction {
  type: SlicesActionsEnum.SET_SLICES;
  payload: ISlice[];
}

export interface SetIsLoadingAction {
  type: SlicesActionsEnum.SET_IS_LOADING;
  payload: boolean;
}

export interface SetErrorAction {
  type: SlicesActionsEnum.SET_ERROR;
  payload: string;
}

export type SlicesAction =
  | SetSlicesAction
  | SetIsLoadingAction
  | SetErrorAction;
