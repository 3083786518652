import React, { useState } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import { LeftBar } from './LeftBar';
import { TopBar } from './TopBar';

const drawerWidth = 230;

interface Props {
  children?: React.ReactNode;
}

export default function PageFrame({ children }: Props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container = window.document.body;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <TopBar drawerWidth={drawerWidth} handleDrawerToggle={handleDrawerToggle} />
      <Box
        component='nav'
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label='mailbox folders'>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth
            }
          }}>
          <LeftBar />
        </Drawer>
        <Drawer
          variant='permanent'
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth
            }
          }}
          open>
          <LeftBar />
        </Drawer>
      </Box>
      <Box
        component='main'
        sx={{
          minHeight: 'calc(100vh - 61px)',
          background: '#f1f5f9',
          flexGrow: 1,
          p: { xl: 3, xs: 2 },
          marginTop: '61px',
          width: `calc(100% - ${drawerWidth}px)`
        }}>
        {children}
      </Box>
    </Box>
  );
}
