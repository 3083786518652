import React, { FC } from 'react';
import styled from 'styled-components';
import { Alert, Button, Grid } from '@mui/material';
import { StyledItem } from './StyledItem';
import { AccountPhotoUpload } from './AccountPhotoUpload';
import { SubscriptionPlan } from './SubscriptionPlan';
import { AccountForm } from './AccountForm';
import { PasswordUpdate } from './PasswordUpdate';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { StyledAlert } from './StyledAlert';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const AccountInformation: FC<{}> = () => {
  const { customer, isLoading, error } = useTypedSelector(state => state.dwolla);

  return (
    <Grid container spacing={{ xl: 3, xs: 2 }}>
      <Grid item xs={12}>
        <StyledItem>
          <StyledWrapper>
            {isLoading
              ? 'Loading Dwolla Account Info...'
              : !customer.isVerified && (
                  <StyledAlert icon={<InfoOutlinedIcon />} severity='warning'>
                    Verify your Business to enable money transfer
                  </StyledAlert>
                )}
            <DataContainer container>
              <StyledGrid item xs={12} md={12} lg={6} order={{ xs: 2, md: 2, lg: 1 }}>
                <Title>Account Information</Title>
                <Container>
                  <AccountPhotoUpload />
                  {error ? (
                    <StyledAlert icon={false} severity='error'>
                      {error}
                    </StyledAlert>
                  ) : (
                    <AccountForm />
                  )}
                  <br />
                  <hr />
                  <br />
                  <Title>Change Password</Title>
                  <PasswordUpdate />
                </Container>
              </StyledGrid>
              <StyledGrid item xs={12} md={12} lg={6} order={{ xs: 1, md: 1, lg: 2 }}>
                <SubscriptionPlan />
              </StyledGrid>
            </DataContainer>
          </StyledWrapper>
        </StyledItem>
      </Grid>
    </Grid>
  );
};

const StyledWrapper = styled.div`
  padding: 31px;
  & > .MuiAlert-root {
    margin: 0 20px;
  }
  @media (max-width: 1199px) {
    & > .MuiAlert-root {
      margin: 20px auto 0;
    }
  }
  @media (max-width: 900px) {
    padding: 1%;
  }
`;
const Title = styled.h3`
  font-size: 18px;
`;

const DataContainer = styled(Grid)`
  max-width: 1300px;
  margin: auto;
  &.MuiGrid-container > .MuiGrid-item {
    border-left: solid 1px #e2e8f0;
    @media (max-width: 1199px) {
      border-left: none;
    }
  }
  &.MuiGrid-container > .MuiGrid-item:first-child {
    border-left: none;
  }
  &.MuiGrid-container > .MuiGrid-item:last-child {
  }
  @media (max-width: 1199px) {
    &.MuiGrid-container > .MuiGrid-item:last-child {
    }
  }
`;

const Container = styled.div`
  & .MuiAlert-root {
    margin: 20px 0;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 20px;
  @media (max-width: 900px) {
  }
`;
