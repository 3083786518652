import { Box, Button } from '@mui/material';
import React, { FC } from 'react';
import styled from 'styled-components';
import Formatter from '../../../helpers/format';

interface Props {
  onClick: (timetable_value: string | null) => void;
  timetable_value: string | null;
}

export const Monthly: FC<Props> = ({ timetable_value, onClick }) => {
  const now: Date = new Date();
  const lastDay: number = 28;

  return (
    <StyledContainer>
      <Styledh5>Every Month on</Styledh5>
      <StyledButtons>
        {[...Array(lastDay)].map((i, n) => {
          now.setDate(n + 1);
          return (
            <Button
              value={Formatter.date(now)}
              key={`Month_${n}`}
              className={
                timetable_value && (new Date(timetable_value)).getDate() === now.getDate()
                  ? 'active'
                  : ''
              }
              onClick={(e: any) => onClick(e.target.value)}
            >
              {n + 1}
            </Button>
          );
        })}
        <Button
          value=""
          className={
            timetable_value === null ? 'active' : ''
          }
          onClick={(e: any) => onClick(null)}
        >
          last day of the month
        </Button>
      </StyledButtons>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)``;

const StyledButtons = styled(Box)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin: auto;
  & .MuiButtonBase-root.MuiButton-root {
    height: 36px;
    min-width: 59px;
    margin: 2px;
    border: 1px solid #cbd5e1;
    border-radius: 3px;
    background-color: transparent;
    color: #475569;
    font-size: 14px;
    &.active {
      background: #334155;
      color: #f8fafc;
      cursor: default;
    }
  }
`;

const Styledh5 = styled.h5`
  text-align: left;
  margin: 2px 0;
  font-weight: normal;
`;
