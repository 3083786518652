import * as AuthActionsCreators from './auth';
import * as BankAccountsActionsCreators from './bankAccounts';
import * as DwollaActionsCreators from './dwolla';
import * as SlicesActionsCreators from './slices';
import * as TransactionActionsCreators from './transaction';

export default {
  ...AuthActionsCreators,
  ...BankAccountsActionsCreators,
  ...DwollaActionsCreators,
  ...SlicesActionsCreators,
  ...TransactionActionsCreators,
};
